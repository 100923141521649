/* eslint-disable no-undef */
import showToast from "utils/showToast";
import useIsMountedRef from "hooks/useIsMountedRef";
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router";
import firebase from "../config/firebase";
// eslint-disable-next-line import/no-cycle
import authGetUser from "./AuthHelpers/authGetUser";

const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
	// USER STATE
	const [cachedUser, setCachedUser] = useState({});
	const [activeUser, setActiveUser] = useState(null);
	const [clientData, setClientData] = useState({});

	const navigate = useNavigate();

	// AUTH STATE
	const [userType, setUserType] = useState("");
	const [authLoading, setAuthLoading] = useState(false);
	const [token, setToken] = useState("");
	// eslint-disable-next-line no-unused-vars
	const [cookies, setCookie] = useCookies(["token"]);

	const [activeProgram, setActiveProgram] = useState("1ee0ef71-d344-4e86-92f0-f09a12cd9c9f");
	// GLOBAL AUTH LOADING STATE
	const isInitialised = useIsMountedRef();

	const refetchUser = async () => {
		try {
			if (activeUser?.uid && token) {
				const userRec = await authGetUser(activeUser?.uid, token);
				setActiveUser(userRec);
				setUserType(userRec.type);
				return userRec;
			}
			return null;
		} catch (err) {
			return err;
		}
	};

	useEffect(async () => {
		setAuthLoading(true);
		firebase.auth().onAuthStateChanged(async (user) => {
			try {
				if (user !== null) {
					const token1 = await user.getIdToken(true);
					// Set emailconfirmed to true
					if (!token1) throw new Error("Could not get token");
					if (token1) setToken(token1);
					setCookie("token", token1, { path: "/" });
					if (!user.uid) throw new Error("Missing uid");
					if (user.uid && token1) {
						const userRec = await authGetUser(user.uid, token1);
						if (userRec.uid) {
							setActiveUser(userRec);
							setUserType(userRec.type);
						}

						if (window.location.pathname === "/") {
							switch (userRec.type) {
								case "ADMIN":
									navigate("/admin");
									break;
								case "MEMBER":
									navigate("/member");
									break;
								case "MANAGER":
									navigate("/client");
									break;
								case "PROVIDER":
									navigate("/provider");
									break;
								default:
									navigate("/");
							}
						}
					}
					setAuthLoading(false);
				} else {
					setActiveUser({});
					setAuthLoading(false);
				}
			} catch (err) {
				// console.log("Error in auth context.");
				//
			}
		});
	}, [setToken, setActiveUser, setUserType, setAuthLoading]);

	const signIn = async (email, password) => {
		try {
			if (!email || !password) throw new Error("Missing email or password (or both)");
			const auth = await firebase.auth().signInWithEmailAndPassword(email, password);
			if (auth?.user?.uid) {
				setAuthLoading(true);
				const token1 = await auth.user.getIdToken(true);
				if (token1) setToken(token1);
				setCookie("token", token1, { path: "/" });
				if (auth.user.uid && token1) {
					const userRec = await authGetUser(auth.user.uid, token1);
					setActiveUser(userRec);
					setUserType(userRec.type);
				}
				setAuthLoading(false);
			} else {
				showToast("Could not access user record, please try again.", "error");
			}

			return auth;
		} catch (error) {
			// console.log(error);
			return error;
		}
	};

	const logout = async () => {
		try {
			await firebase.auth().signOut();
			setUserType("");
			setToken("");
			setActiveUser({});
			return null;
		} catch (err) {
			return err;
		}
	};

	const impersonateUser = async (user) => {
		try {
			setCachedUser(activeUser);
			setActiveUser(user);
			if (user.type === "ADMIN") {
				navigate("/admin");
			} else if (user.type === "MANAGER") {
				navigate("/client");
			} else if (user.type === "PROVIDER") {
				navigate("/provider");
			} else {
				navigate("/member");
			}
			showToast("You are now impersonating");
		} catch (error) {
			showToast("Something went wrong. Please try again.", "error");
		}
	};

	const stopImpersonation = async () => {
		try {
			setActiveUser(cachedUser);

			// Navigate back to the user's people page
			if (cachedUser.type === "ADMIN") {
				navigate("/admin/users/");
			} else if (cachedUser.type === "MANAGER") {
				navigate("/client/people/");
			} else {
				navigate("/");
			}

			setCachedUser({});

			showToast("Impersonation finished");
		} catch (error) {
			showToast("Something went wrong. Please try again.", "error");
		}
	};

	return (
		<AuthContext.Provider
			value={{
				cachedUser,
				setCachedUser,
				activeUser,
				setActiveUser,
				userType,
				setUserType,
				clientData,
				setClientData,
				signIn,
				isInitialised,
				logout,
				authLoading,
				setAuthLoading,
				token,
				refetchUser,
				setActiveProgram,
				activeProgram,
				impersonateUser,
				stopImpersonation,
			}}>
			{children}
		</AuthContext.Provider>
	);
};

export default AuthContext;
