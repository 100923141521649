import useAuth from "hooks/useAuth";

const OnlyShowIf = ({ children, type }) => {
	const { activeUser } = useAuth();

	const typeArr = Array.isArray(type) ? type : [type];

	if (activeUser && typeArr.includes(activeUser?.type)) {
		return children;
	}

	return null;
};

export default OnlyShowIf;
