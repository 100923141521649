/* eslint-disable jsx-a11y/anchor-is-valid */
import { Box, Grid, Link, Typography } from "@material-ui/core";
import CategoryInput from "components/CategoryInput/CategoryInput";
import EditableTable from "components/EditableTable/EditableTable";
import FormField from "components/FormField/FormField";
import OnlyShowIf from "components/OnlyShowIf/OnlyShowIf";
import StandardButton from "components/StandardButton/StandardButton";
import useAuth from "hooks/useAuth";
import ArrowRight from "icons/ArrowRight";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import availabilityTypes from "utils/availabilityTypes";
import deliveryTypes from "utils/deliveryTypes";
import formatDate from "utils/formatDate";
import formatNumber from "utils/formatNumber";
import timeIncrements from "utils/timeIncrements";
import workloadTypes from "utils/workloadTypes";

const CourseOverview = ({
	pageData,
	togglePurchaseModal,
	editMode,
	extraData,
	setExtraData,
	categories,
	setCategories,
}) => {
	const navigate = useNavigate();
	const location = useLocation();
	const baseLink = `/${location.pathname.split("/")[1]}/marketplace/`;
	const { activeUser } = useAuth();

	useEffect(() => {
		if (pageData?.offeringId) {
			setCategories(pageData?.categories || []);
		}
	}, [pageData]);

	const isAdmin = activeUser?.type === "ADMIN";
	const tableRows = [
		{
			label: "Provider",
			value: () => (
				<Link
					onClick={() => navigate(`${baseLink}search/?providerId=${pageData?.provider?.providerId}`)}
					color="textSecondary">
					<Typography color="textSecondary" sx={{ cursor: "pointer" }} variant="subtitle2">
						{pageData?.provider?.name}
					</Typography>
				</Link>
			),
			field: () => (
				<Link
					onClick={() => navigate(`${baseLink}search/?providerId=${pageData?.provider?.providerId}`)}
					color="textSecondary">
					<Typography color="textSecondary" sx={{ cursor: "pointer" }} variant="subtitle2">
						Provider is {pageData?.provider?.name}
					</Typography>
				</Link>
			),
		},
		{
			label: "Delivery",
			value: () => <div>{deliveryTypes.find((v) => v.value === pageData.delivery)?.label}</div>,
			field: () => (
				<FormField
					label="Delivery"
					type="select"
					name="delivery"
					defaultValue={deliveryTypes?.find((loc) => loc.value === pageData.delivery)?.value}
					required
					options={deliveryTypes}
					key={pageData.delivery}
				/>
			),
		},
		{
			label: "Categories",
			value: () => (
				<div>{pageData?.categories?.length ? pageData?.categories?.map((c) => `${c} `) : "None Specified"}</div>
			),
			field: () => <CategoryInput value={categories} setValue={setCategories} />,
		},
	];

	if (pageData.sessions) {
		tableRows.push({
			label: "Sessions",
			value: () => <div>{pageData.sessions}</div>,
			field: () => null,
		});
	}

	if (pageData.type === "COURSE") {
		tableRows.push({
			label: "Duration",
			value: () => (
				<div>
					{pageData?.data?.durationMulti} {pageData?.data?.durationIncrement}
				</div>
			),
			field: () => (
				<Grid container spacing={1}>
					<Grid item xs={5}>
						<FormField
							label="Duration"
							type="number"
							defaultValue={pageData?.data?.durationMulti}
							required
							name="durationMulti"
							onChange={(val) => setExtraData({ ...extraData, durationMulti: val.target.value })}
							key={pageData?.data?.durationMulti}
							value={extraData?.durationMulti}
						/>
					</Grid>
					<Grid item xs={7}>
						<FormField
							label="Increment"
							type="select"
							required
							defaultValue={
								timeIncrements?.find((loc) => loc.value === pageData.data?.durationIncrement)?.value
							}
							name="durationIncrement"
							options={timeIncrements}
							onChange={(val) => setExtraData({ ...extraData, durationIncrement: val.value })}
							key={pageData?.data?.durationIncrement}
							value={extraData?.durationIncrement}
						/>
					</Grid>
				</Grid>
			),
		});

		tableRows.push({
			label: "Availability",
			value: () => <div>{availabilityTypes?.find((loc) => loc.value === pageData?.availability)?.label}</div>,
			field: () => (
				<FormField
					label="Availability"
					type="select"
					name="availability"
					defaultValue={availabilityTypes?.find((loc) => loc.value === pageData?.availability)?.value}
					required
					options={availabilityTypes}
					key={pageData?.availability}
				/>
			),
		});

		tableRows.push({
			label: "Workload",
			value: () => (
				<div>{workloadTypes.find((t) => t.value === pageData?.data?.workload)?.label || "Not Specified"}</div>
			),
			field: () => (
				<FormField
					label="Workload"
					type="select"
					name="workload"
					defaultValue={workloadTypes?.find((loc) => loc.value === pageData?.data?.workload)?.value}
					required
					options={workloadTypes}
					key={pageData?.data?.workload}
					onChange={(val) => setExtraData({ ...extraData, workload: val.value })}
					value={extraData?.workload}
				/>
			),
		});
	}

	if (pageData?.type === "EVENT") {
		tableRows.push({
			label: "Dates",
			value: () => (
				<div>
					{formatDate(pageData.startTime)} - {formatDate(pageData.endTime)}
				</div>
			),
			field: () => null,
		});
	}

	tableRows.push({
		label: isAdmin ? "Sale Price" : "Price",
		value: () => (
			<div>
				<OnlyShowIf type="MEMBER">{formatNumber(pageData.salePrice, "points")}</OnlyShowIf>
				<OnlyShowIf type={["MANAGER", "ADMIN", "PROVIDER"]}>{formatNumber(pageData.salePrice)}</OnlyShowIf>
			</div>
		),
		field: () => <div>Sale Price Auto Calculated</div>,
	});

	if (isAdmin) {
		tableRows.push({
			label: "Cost Price",
			value: () => (
				<div>
					<OnlyShowIf type={["MANAGER", "ADMIN", "PROVIDER"]}>{formatNumber(pageData.costPrice)}</OnlyShowIf>
				</div>
			),
			field: () => (
				<FormField
					label="Cost Price"
					type="currency"
					name="costPrice"
					defaultValue={pageData.costPrice}
					required
					key={pageData.costPrice}
				/>
			),
		});
		tableRows.push({
			label: "Created At",
			value: () => <div>{formatDate(pageData.createdAt) || "-"}</div>,
			field: () => <div>Last Updated {formatDate(pageData.createdAt) || "-"}</div>,
		});
	}

	return (
		<>
			<EditableTable
				title="Details"
				editMode={editMode}
				size="sm"
				rows={tableRows}
				footer={
					<Box
						flexDirection="row-reverse"
						sx={{
							alignItems: "center",
							display: "flex",
							pl: 2,
							pr: 3,
							py: 1,
						}}>
						<OnlyShowIf type="MEMBER">
							<StandardButton icon={<ArrowRight />} text="Purchase" onClick={togglePurchaseModal} />
						</OnlyShowIf>
					</Box>
				}
			/>
		</>
	);
};

export default CourseOverview;
