import { experimentalStyled } from "@material-ui/core/styles";

const LabelRoot = experimentalStyled("span")(({ theme, styleProps }) => {
	const backgroundColor = theme.palette[styleProps.color].main;

	return {
		alignItems: "center",
		backgroundColor,
		borderRadius: 30,
		color: "#FFF",
		cursor: "default",
		display: "inline-flex",
		flexGrow: 0,
		flexShrink: 0,
		fontFamily: theme.typography.fontFamily,
		fontSize: theme.typography.pxToRem(11),
		fontWeight: theme.typography.fontWeightMedium,
		justifyContent: "center",
		letterSpacing: 0.5,
		minWidth: 20,
		padding: "10px 20px",
		textTransform: "uppercase",
		whiteSpace: "nowrap",
	};
});

const Label = ({ color = "primary", children }) => {
	const styleProps = { color };

	return <LabelRoot styleProps={styleProps}>{children}</LabelRoot>;
};

export default Label;
