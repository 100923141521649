import { useMutation, useQuery } from "@apollo/client";
import { Box, Card, Container, Divider, Grid, Typography } from "@material-ui/core";
import { Cancel, Download } from "@material-ui/icons";
import UpdateOffering from "apollo/mutations/UpdateOffering";
import GetOffering from "apollo/queries/GetOffering";
import FormField from "components/FormField/FormField";
import ImageHeader from "components/ImageHeader/ImageHeader";
import OnlyShowIf from "components/OnlyShowIf/OnlyShowIf";
import PageHeader from "components/PageHeader/PageHeader";
import StandardButton from "components/StandardButton/StandardButton";
import useAuth from "hooks/useAuth";
import ArrowRight from "icons/ArrowRight";
import PencilAlt from "icons/PencilAlt";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import currencyStringToFloat from "utils/currencyStringToFloat";
import parseHtml from "utils/parseHtml";
import showToast from "utils/showToast";
import CourseOverview from "./about/CourseOverview";
import AddToPlaylistModal from "./components/AddToPlaylistModal";
import ConfirmPurchaseModal from "./components/ConfirmPurchaseModal";
import CourseDateTable from "./components/CourseDateTable";

const ViewOffering = () => {
	const { id, oid } = useParams();
	const { activeUser } = useAuth();
	// eslint-disable-next-line no-unused-vars
	const navigate = useNavigate();
	const [playlistModal, setPlaylistModal] = useState({ open: false, modalType: "add", offeringType: null });
	const [confirmPurchaseModal, setConfirmPurchaseModal] = useState({ open: false });
	const [editMode, setEditMode] = useState(false);
	const location = useLocation();
	const [extraData, setExtraData] = useState({});
	const [updateOffering] = useMutation(UpdateOffering);
	const [categories, setCategories] = useState([]);

	const toggleModal = () => setPlaylistModal({ ...playlistModal, open: !playlistModal.open });

	// const correctQuery = typesArr.find((t) => t.type === type);

	const { data, refetch, loading } = useQuery(GetOffering, {
		notifyOnNetworkStatusChange: true,
		variables: { payload: { offeringId: oid } },
		skip: !oid,
	});

	const pageData = data?.getOffering || {};

	const baseLink = `/${location.pathname.split("/")[1]}/marketplace/`;

	// If this offering was not found, redirect and throw error
	useEffect(() => {
		if (!loading && Object.keys(pageData).length === 0) {
			navigate(baseLink);
			showToast("This program is no longer available", "error");
		}
		if (Object.keys(pageData?.data || {}).length > 0) {
			setExtraData(pageData?.data);
		}
	}, [pageData]);

	const togglePurchaseModal = () => {
		setConfirmPurchaseModal({ open: !confirmPurchaseModal.open, costCurrency: pageData.salePrice });
	};

	// Handle data for editing offering
	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			// console.log(deliveryTypes.filter((d) => d.label === e.target.delivery.value));
			const payload = {
				variables: {
					payload: {
						offeringId: oid,
						name: e.target.name.value,
						delivery: e.target.delivery.value,
						availability: e.target?.availability?.value || "ANYTIME",
						costPrice: currencyStringToFloat(e.target.costPrice.value),
						description: e.target.description.value,
						summary: e.target.summary.value,
						categories,
						data: extraData,
					},
				},
			};
			const create = await updateOffering(payload);
			if (create.data.updateOffering.__typename === "Error") throw new Error(create.data.updateOffering.message);
			if (refetch) await refetch();
			setEditMode(false);
			return;
		} catch (err) {
			showToast(err.message, "error");
		}
	};

	return (
		<>
			<Helmet>
				<title>Careerbase | View Offering</title>
			</Helmet>
			<ImageHeader
				refetch={refetch}
				title={pageData.name}
				subtitle={pageData.provider?.name}
				coverPic={pageData.coverPic}
				displayPic={pageData?.provider?.displayPic}
				ownerId={pageData.offeringId}
				ownerType="offering"
				subtitleUrl={`${baseLink}search/?providerId=${pageData?.provider?.providerId}`}
				buttons={
					<>
						<OnlyShowIf type={["MANAGER", "ADMIN"]}>
							<StandardButton
								buttonStyle="primary"
								onClick={() =>
									setPlaylistModal({
										open: true,
										modalType: "add",
										offeringType: "COACH",
									})
								}
								text="Add To Playlist"
							/>

							<StandardButton
								buttonStyle="secondary"
								onClick={() =>
									setPlaylistModal({
										open: true,
										modalType: "remove",
										offeringType: "COACH",
									})
								}
								text="Remove From Playlist"
							/>
						</OnlyShowIf>
						<OnlyShowIf type="MEMBER">
							<StandardButton icon={<ArrowRight />} text="Purchase" onClick={togglePurchaseModal} />
						</OnlyShowIf>
					</>
				}
				disableDisplayPic
				disableCoverPic={activeUser.type !== "ADMIN"}
			/>
			<Box>
				<form onSubmit={handleSubmit}>
					<Box sx={{ mt: 3 }}>
						<Container maxWidth="lg">
							<Box sx={{ py: 3 }}>
								<OnlyShowIf type={["ADMIN"]}>
									<PageHeader
										title="Offering Details"
										subheader
										buttons={
											editMode ? (
												<>
													<StandardButton
														buttonStyle="tertiary purple"
														text="Cancel"
														icon={<Cancel />}
														onClick={() => setEditMode(false)}
														key="cwq98q3f41h9"
													/>
													<StandardButton
														buttonStyle="secondary"
														text="Save Changes"
														loading={loading}
														icon={<Download />}
														type="submit"
														key="q32435yresdfff"
													/>
												</>
											) : (
												<StandardButton
													buttonStyle="tertiary purple"
													text="Edit"
													type="button"
													key="q32435yref"
													icon={<PencilAlt />}
													onClick={() => setEditMode(true)}
												/>
											)
										}
									/>
								</OnlyShowIf>
								<Grid container spacing={3}>
									<Grid item lg={8} md={6} xs={12}>
										<Card>
											<Box sx={{ p: 2 }}>
												<Typography color="textPrimary" variant="h6">
													About
												</Typography>
											</Box>

											<Divider />
											{editMode && (
												<Box sx={{ p: 2 }}>
													<Grid item xs={12} sx={{ mb: 2 }}>
														<FormField
															label="Name"
															type="text"
															name="name"
															disabled={loading}
															defaultValue={pageData?.name}
														/>
													</Grid>
													<Grid container spacing={2}>
														<Grid item xs={12}>
															<FormField
																label="Summary (for search results)"
																type="multiline"
																name="summary"
																disabled={loading}
																defaultValue={pageData?.summary}
															/>
														</Grid>
													</Grid>
												</Box>
											)}
											<Box sx={{ px: 2, pb: 2, pt: 1 }}>
												{!editMode ? (
													<Typography>{parseHtml(pageData?.description)}</Typography>
												) : (
													<FormField
														label="Description"
														type="richText"
														defaultValue={pageData?.description}
														name="description"
														disabled={loading}
													/>
												)}
											</Box>
										</Card>
										{pageData?.type === "COURSE" && pageData?.availability === "DATED" && (
											<Box sx={{ mt: 3 }}>
												<CourseDateTable
													dates={pageData?.courseDates}
													refetch={refetch}
													providerId={id}
													offeringId={oid}
													editable={activeUser?.type === "ADMIN"}
												/>
											</Box>
										)}
									</Grid>
									<Grid item lg={4} md={6} xs={12}>
										<CourseOverview
											pageData={pageData}
											togglePurchaseModal={togglePurchaseModal}
											editMode={editMode}
											extraData={extraData}
											setExtraData={setExtraData}
											categories={categories}
											setCategories={setCategories}
										/>
									</Grid>
								</Grid>
							</Box>
						</Container>
					</Box>
				</form>
				<AddToPlaylistModal
					open={playlistModal.open}
					onClose={toggleModal}
					modalType={playlistModal.modalType}
					offeringType={playlistModal.offeringType}
				/>
				<ConfirmPurchaseModal
					open={confirmPurchaseModal.open}
					onClose={togglePurchaseModal}
					costCurrency={confirmPurchaseModal.costCurrency}
					// offeringType={correctQuery.offeringType}
					offeringName={pageData?.name}
					providerId={pageData?.providerId}
				/>
			</Box>
		</>
	);
};

export default ViewOffering;
