import {
	Box,
	Card,
	CardHeader,
	Checkbox,
	Divider,
	Skeleton,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TablePagination,
	TableRow,
	Typography,
} from "@material-ui/core";
import Scrollbar from "components/common/Layout/Scrollbar";
import StandardButton from "components/StandardButton/StandardButton";
import React, { useEffect, useState } from "react";

const DataTable = ({
	columns,
	data,
	bulkActions,
	loading,
	title,
	action,
	customNoDataComponent,
	disablePagination,
}) => {
	// Loading state for actions (e.g. delete)
	const [tableLoading, setTableLoading] = useState(false);

	useEffect(() => {
		setTableLoading(loading);
	}, [loading]);

	// Page number
	const [page, setPage] = useState(0);

	// Results per page
	const [limit, setLimit] = useState(5);

	// Rows that are selected
	const [selectedRows, setSelectedRows] = useState([]);

	// Handle pagination change
	const handlePageChange = (event, newPage) => {
		setPage(newPage);
	};

	// Handle results per page change
	const handleLimitChange = (event) => {
		setLimit(parseInt(event.target.value, 10));
	};

	// Pagination funct
	const applyPagination = (customers, pageNumber, limitCount) =>
		customers.slice(pageNumber * limitCount, pageNumber * limitCount + limitCount);

	// Data once paginated
	const paginatedData = !disablePagination ? applyPagination(data, page, limit) : data;

	// Handle selection of all rows
	const handleSelectAllRows = (checked) => {
		if (checked) {
			setSelectedRows(data);
		} else {
			setSelectedRows([]);
		}
	};

	const handleSelectOneRow = (row, checked) => {
		// Remove any instances from array at the moment
		// This prevents duplicates
		const filteredSelectedRows = selectedRows.filter((sRow) => sRow !== row);

		if (checked) {
			setSelectedRows([...filteredSelectedRows, row]);
		} else {
			setSelectedRows(filteredSelectedRows);
		}
	};

	const SkellyRender = () => {
		const x = [];
		// eslint-disable-next-line no-plusplus
		for (let i = 0; i < 7; i++) x.push("1");
		return x.map(() => (
			<TableRow>
				{columns.map(() => (
					<TableCell>
						<Skeleton />
					</TableCell>
				))}
			</TableRow>
		));
	};

	// Handle developer not providing some required props:
	if (!columns) {
		throw new Error("You need to provide a columns array for DataTable");
	}
	if (!data) {
		throw new Error("You need to provide a data array for DataTable, even if an empty array");
	}
	if (action && !title) {
		throw new Error("You must provide a title value when using action prop");
	}
	return (
		<div>
			<Card>
				{title && (
					<>
						<CardHeader title={title} action={action || null} />
						<Divider />
					</>
				)}
				{selectedRows.length > 0 && (
					<Box sx={{ position: "relative" }}>
						<Box
							sx={{
								backgroundColor: "background.paper",
								mt: "6px",
								position: "absolute",
								px: "4px",
								width: "100%",
								zIndex: 2,
							}}>
							<Checkbox
								color="primary"
								checked={selectedRows.length === data.length}
								onChange={(e) => handleSelectAllRows(e.target.checked)}
							/>

							{bulkActions?.map((bulkAction) => (
								<StandardButton
									onClick={async () => {
										setTableLoading(true);
										await bulkAction.function(selectedRows);
										setSelectedRows([]);
										setTableLoading(false);
									}}
									buttonStyle="secondary"
									sx={{ ml: 2 }}
									type="secondary"
									text={bulkAction.name}
									loading={tableLoading}
								/>
							))}
						</Box>
					</Box>
				)}
				<Scrollbar>
					<Box sx={{ minWidth: 700 }}>
						<Table>
							<TableHead>
								<TableRow>
									{/* This empty one exists to allow for checkboxes */}
									{bulkActions?.length > 0 && (
										<TableCell padding="checkbox">
											<Checkbox
												color="primary"
												checked={selectedRows.length === data.length}
												onChange={(e) => handleSelectAllRows(e.target.checked)}
											/>
										</TableCell>
									)}
									{columns.map((col) => (
										<TableCell>{col.label}</TableCell>
									))}
								</TableRow>
							</TableHead>

							<TableBody>
								{paginatedData.map((row) => (
									<TableRow>
										{bulkActions?.length > 0 && (
											<TableCell padding="checkbox">
												<Checkbox
													checked={selectedRows.some((sRow) => row === sRow)}
													color="primary"
													onChange={(e) => handleSelectOneRow(row, e.target.checked)}
												/>
											</TableCell>
										)}
										{columns.map((col) => {
											const CustomCell = () => (col.cell ? col.cell({ row }) : null);
											return (
												<TableCell>
													{row[col.selector] ? row[col.selector] : <CustomCell row={row} />}
												</TableCell>
											);
										})}
									</TableRow>
								))}
								{loading && <SkellyRender />}
							</TableBody>
						</Table>
						{data.length === 0 && !loading && (
							<>
								{customNoDataComponent || (
									<Box
										display="flex"
										alignItems="center"
										justifyContent="center"
										sx={{ width: "100%", p: 3 }}>
										<Typography>There&apos;s nothing to see here.</Typography>
									</Box>
								)}
							</>
						)}
					</Box>
				</Scrollbar>
				{!disablePagination && !loading && data.length !== 0 && (
					<TablePagination
						component="div"
						count={data.length}
						onPageChange={handlePageChange}
						onRowsPerPageChange={handleLimitChange}
						page={page}
						rowsPerPage={limit}
						rowsPerPageOptions={[5, 10, 25]}
					/>
				)}
			</Card>
		</div>
	);
};

export default DataTable;
