import { useMutation, useQuery } from "@apollo/client";
import { Box, Divider, Modal, Paper, Typography } from "@material-ui/core";
import AddToPlaylist from "apollo/mutations/AddToPlaylist";
import RemoveFromPlaylist from "apollo/mutations/RemoveFromPlaylist";
import GetPlaylists from "apollo/queries/GetPlaylists";
import FormField from "components/FormField/FormField";
import StandardButton from "components/StandardButton/StandardButton";
import showToast from "utils/showToast";
import useAuth from "hooks/useAuth";
import { useState } from "react";
import { useParams } from "react-router";

const AddToPlaylistModal = ({ open, onClose, modalType }) => {
	const [loading, setLoading] = useState(false);
	const { activeUser } = useAuth();

	// This code sets the clientId if a manager. Otherwise, we're an admin so you should see all playlists
	let vars = false;
	if (activeUser?.type === "MANAGER") {
		vars = { payload: { clientId: activeUser.clientId } };
	} else if (activeUser?.type === "ADMIN") {
		vars = { payload: { clientId: null } };
	}

	const { data } = useQuery(GetPlaylists, {
		notifyOnNetworkStatusChange: true,
		variables: vars,
		skip: vars === false,
	});

	const [addToPlaylist] = useMutation(AddToPlaylist);
	const [removeFromPlaylist] = useMutation(RemoveFromPlaylist);

	const { oid } = useParams();

	const handleSubmit = async (e) => {
		e.preventDefault();
		e.persist();
		try {
			setLoading(true);

			const payload = {
				variables: {
					payload: {
						offeringId: oid,
						playlistId: e.target.playlist.value,
					},
				},
			};

			if (modalType === "add") {
				const create = await addToPlaylist(payload);

				if (create.data.addToPlaylist.__typename === "Error") {
					throw new Error(create.data.addToPlaylist.message);
				}
			} else {
				const create = await removeFromPlaylist(payload);

				if (create.data.removeFromPlaylist.__typename === "Error") {
					throw new Error(create.data.removeFromPlaylist.message);
				}
			}

			setLoading(false);

			// Close Modal
			onClose();

			// Show success
			showToast(`Successfully ${modalType === "add" ? "added to" : "removed from"} program`);
		} catch (error) {
			setLoading(false);
			showToast(error.message || "Something went wrong. Please try again.", "error");
		}
	};

	const playlistOptions = data?.getPlaylists.map((p) => ({ label: p.name, value: p.playlistId })) || [];

	return (
		<Modal open={open} onClose={onClose}>
			<Paper
				elevation={12}
				sx={{
					width: 600,
					mx: "auto",
					position: "absolute",
					top: "20%",
					left: "50%",
					transform: "translate(-50%, -50%)",
				}}>
				<Typography sx={{ m: 2 }} variant="h5">
					Select playlist to {modalType === "add" ? "add To" : "remove from"}
				</Typography>
				<Divider />
				<Box sx={{ m: 2 }}>
					<form onSubmit={handleSubmit}>
						<FormField
							label="Choose Playlist"
							type="select"
							options={playlistOptions}
							required
							disabled={loading}
							name="playlist"
						/>
						<br />
						<StandardButton
							type="submit"
							text={modalType === "add" ? "Add to playlist" : "Remove from playlist"}
							loading={loading}
						/>
					</form>
				</Box>
			</Paper>
		</Modal>
	);
};

export default AddToPlaylistModal;
