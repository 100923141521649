/* eslint-disable import/no-unresolved */
import { ThemeProvider } from "@material-ui/core";
import LoadingScreen from "components/LoadingScreen/LoadingScreen";
import maintenceMode from "config/maintenceMode";
import { SnackbarProvider } from "notistack";
import MaintenancePage from "pages/Maintence";
import FullStory from "react-fullstory";
import { useRoutes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GlobalStyles from "./components/common/GlobalStyles";
import RTL from "./components/common/Layout/RTL";
import useAuth from "./hooks/useAuth";
import useScrollReset from "./hooks/useScrollReset";
import useSettings from "./hooks/useSettings";
import routes from "./routes";
import createThemes from "./theme";

const App = () => {
	const content = useRoutes(routes);
	const { settings } = useSettings();
	const { isInitialised } = useAuth();
	useScrollReset();

	const theme = createThemes({
		direction: settings.direction,
		responsiveFontSizes: settings.responsiveFontSizes,
		roundedCorners: settings.roundedCorners,
		theme: settings.theme,
	});

	return (
		<ThemeProvider theme={theme}>
			{!maintenceMode ? (
				<>
					<ToastContainer />
					{/* If production, record in fullstory */}
					{process.env.REACT_APP_AUTH_DOMAIN === "careerbasev3-production.firebaseapp.com" && (
						<FullStory org="15T47K" />
					)}
					<RTL direction={settings.direction}>
						<SnackbarProvider dense maxSnack={3}>
							<GlobalStyles />

							{isInitialised.current ? content : <LoadingScreen />}
						</SnackbarProvider>
					</RTL>
				</>
			) : (
				<MaintenancePage />
			)}
		</ThemeProvider>
	);
};

export default App;
