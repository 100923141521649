import { gql } from "@apollo/client";

const GetCategories = gql`
	query GetCategories {
		getCategories {
			... on Categories {
				categoryId
				value
				label
			}
			... on Error {
				message
			}
		}
	}
`;

export default GetCategories;
