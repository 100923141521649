import merge from "lodash/merge";
import { createTheme, responsiveFontSizes } from "@material-ui/core/styles";
import THEMES from "../constants";
import { lightShadows, darkShadows } from "./shadows";

const baseOptions = {
	direction: "ltr",
	components: {
		MuiAvatar: {
			styleOverrides: {
				fallback: {
					height: "75%",
					width: "75%",
				},
			},
		},
		MuiButton: {
			styleOverrides: {
				root: {
					textTransform: "none",
					borderRadius: "7px",
					padding: "10px 20px",
				},
			},
		},
		MuiCardHeader: {
			defaultProps: {
				titleTypographyProps: {
					variant: "h6",
				},
			},
		},
		MuiLinearProgress: {
			styleOverrides: {
				root: {
					borderRadius: 3,
					overflow: "hidden",
				},
			},
		},
		MuiListItemIcon: {
			styleOverrides: {
				root: {
					minWidth: "auto",
					marginRight: "16px",
				},
			},
		},
	},
	typography: {
		button: {
			fontWeight: 200,
		},
		fontFamily: "Metropolis Medium",
		h1: {
			fontWeight: 600,
			fontSize: "3.5rem",
			fontFamily: "Metropolis Bold",
		},
		h2: {
			fontWeight: 600,
			fontSize: "3rem",
			fontFamily: "Metropolis Bold",
		},
		h3: {
			fontWeight: 600,
			fontSize: "2.25rem",
			fontFamily: "Metropolis Bold",
		},
		h4: {
			fontWeight: 600,
			fontSize: "2rem",
			fontFamily: "Metropolis Bold",
		},
		h5: {
			fontWeight: 600,
			fontSize: "1.5rem",
			fontFamily: "Metropolis Bold",
		},
		h6: {
			fontWeight: 600,
			fontSize: "1.125rem",
			fontFamily: "Metropolis Bold",
		},
		overline: {
			fontWeight: 600,
		},
	},
};

const themesOptions = {
	[THEMES.LIGHT]: {
		components: {
			MuiInputBase: {
				styleOverrides: {
					input: {
						"&::placeholder": {
							opacity: 0.86,
							color: "#42526e",
						},
					},
				},
			},
		},
		palette: {
			action: {
				active: "#6b778c",
			},
			background: {
				default: "#f4f5f7",
				paper: "#ffffff",
			},
			error: {
				contrastText: "#ffffff",
				main: "#f44336",
			},
			mode: "light",
			primary: {
				contrastText: "#ffffff",
				main: "#233C53",
			},
			secondary: {
				contrastText: "#ffffff",
				main: "#F5333F",
			},
			success: {
				contrastText: "#ffffff",
				main: "#4caf50",
			},
			text: {
				primary: "#172b4d",
				secondary: "#6b778c",
			},
			warning: {
				contrastText: "#ffffff",
				main: "#ff9800",
			},
		},
		shadows: lightShadows,
	},
	[THEMES.DARK]: {
		components: {
			MuiTableCell: {
				styleOverrides: {
					root: {
						borderBottom: "1px solid rgba(145, 158, 171, 0.24)",
					},
				},
			},
		},
		palette: {
			background: {
				default: "#171c24",
				paper: "#222b36",
			},
			divider: "rgba(145, 158, 171, 0.24)",
			error: {
				contrastText: "#ffffff",
				main: "#f44336",
			},
			mode: "dark",
			primary: {
				contrastText: "#ffffff",
				main: "#EEF4F9",
			},
			secondary: {
				contrastText: "#000000",
				main: "#EEF4F9",
			},
			success: {
				contrastText: "#ffffff",
				main: "#4caf50",
			},
			text: {
				primary: "#ffffff",
				secondary: "#919eab",
			},
			warning: {
				contrastText: "#ffffff",
				main: "#ff9800",
			},
		},
		shadows: darkShadows,
	},
	[THEMES.NATURE]: {
		components: {
			MuiTableCell: {
				styleOverrides: {
					root: {
						borderBottom: "1px solid rgba(145, 158, 171, 0.24)",
					},
				},
			},
		},
		palette: {
			background: {
				default: "#1c2531",
				paper: "#293142",
			},
			divider: "rgba(145, 158, 171, 0.24)",
			error: {
				contrastText: "#ffffff",
				main: "#f44336",
			},
			mode: "dark",
			primary: {
				contrastText: "#ffffff",
				main: "#01ab56",
			},
			success: {
				contrastText: "#ffffff",
				main: "#4caf50",
			},
			text: {
				primary: "#ffffff",
				secondary: "#919eab",
			},
			warning: {
				contrastText: "#ffffff",
				main: "#ff9800",
			},
		},
		shadows: darkShadows,
	},
};

const createThemes = (config = {}) => {
	let themeOptions = themesOptions[config.theme];

	if (!themeOptions) {
		// console.warn(new Error(`The theme ${config.theme} is not valid`));
		themeOptions = themesOptions[THEMES.LIGHT];
	}

	let theme = createTheme(
		merge(
			{},
			baseOptions,
			themeOptions,
			{
				...(config.roundedCorners && {
					shape: {
						borderRadius: 16,
					},
				}),
			},
			{
				direction: config.direction,
			}
		)
	);

	if (config.responsiveFontSizes) {
		theme = responsiveFontSizes(theme);
	}

	return theme;
};

export default createThemes;
