import { useMutation } from "@apollo/client";
import { Box, Divider, Modal, Paper, Typography } from "@material-ui/core";
import PurchaseOffering from "apollo/mutations/PurchaseOffering";
import StandardButton from "components/StandardButton/StandardButton";
import showToast from "utils/showToast";
import AuthContext from "contexts/AuthContext";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import formatNumber from "utils/formatNumber";

const ConfirmPurchaseModal = ({ open, onClose, costCurrency }) => {
	const [loading, setLoading] = useState(false);
	const { activeProgram, activeUser, refetchUser } = useContext(AuthContext);
	const [activeAccount, setActiveAccount] = useState({});

	const [purchaseOffering] = useMutation(PurchaseOffering);

	useEffect(() => {
		const currentProgramAccount = activeUser?.programAccounts?.find((p) => p.programId === activeProgram.programId);
		// const totalFunds = currentProgramAccount?.balance;

		setActiveAccount(currentProgramAccount);
	}, [activeProgram, activeUser]);

	const { oid } = useParams();

	const navigate = useNavigate();

	const handlePurchase = async () => {
		try {
			setLoading(true);

			const payload = {
				variables: {
					payload: {
						userId: parseInt(activeUser.id, 10),
						programId: activeProgram.programId,
						offeringId: oid,
						salePrice: costCurrency,
					},
				},
			};

			const create = await purchaseOffering(payload);

			if (create.data.purchaseOffering.__typename === "Error") {
				throw new Error(create.data.purchaseOffering.message);
			}

			setLoading(false);

			await refetchUser();

			// Close Modal
			onClose();

			navigate("/member/program");

			// Show success
			showToast(`Successfully purchased offering`);
		} catch (error) {
			setLoading(false);
			showToast("Something went wrong. Please try again.", "error");
		}
	};

	return (
		<Modal open={open} onClose={onClose}>
			<Paper
				elevation={12}
				sx={{
					width: 600,
					mx: "auto",
					position: "absolute",
					top: "20%",
					left: "50%",
					transform: "translate(-50%, -50%)",
				}}>
				<Typography sx={{ m: 2 }} variant="h5">
					Are you sure?
				</Typography>
				<Divider />
				<Box sx={{ m: 2 }}>
					{costCurrency < activeAccount?.balance ? (
						<>
							<Typography>You are about to make a purchase from {activeProgram?.name}.</Typography>
							<br />

							<Typography>
								You currently have {formatNumber(activeAccount?.balance, "points")}.
							</Typography>
							<Typography>
								By redeeming this offering you will use {formatNumber(costCurrency, "points")}.
							</Typography>
							<br />
							<br />
							<StandardButton
								type="submit"
								text="Confirm Purchase"
								loading={loading}
								onClick={() => handlePurchase()}
							/>
						</>
					) : (
						<Typography>
							You don&apos;t have enough points for this offering. Please book something else.
						</Typography>
					)}
				</Box>
			</Paper>
		</Modal>
	);
};

export default ConfirmPurchaseModal;
