import { useMutation, useQuery } from "@apollo/client";
import CreateCategory from "apollo/mutations/CreateCategory";
import GetCategories from "apollo/queries/GetCategories";
import FormField from "components/FormField/FormField";
import showToast from "utils/showToast";
import React, { useState } from "react";
import wait from "utils/wait";

const CategoryInput = ({ value, setValue }) => {
	const { data, refetch } = useQuery(GetCategories);
	const [loading, setLoading] = useState(false);

	const cats = data?.getCategories || [];

	const [createCategory] = useMutation(CreateCategory);

	const handleCreate = async (val) => {
		try {
			setLoading(true);
			const create = await createCategory({
				variables: {
					payload: {
						value: val,
					},
				},
			});

			if (create.data.createCategory.__typename === "Error") throw new Error(create.data.createCategory.message);
			await wait(1000);
			await refetch();
			setValue([...value, val]);
			showToast("Successfully added new category");
			setLoading(false);
		} catch (err) {
			setLoading(false);
			showToast(err.message, "error");
		}
	};

	return (
		<div>
			<FormField
				required
				type="tags"
				options={cats}
				value={cats.filter((c) => value.includes(c.value))}
				onChange={(v) => setValue(v.map((val) => val.value) || [])}
				placeholder="Categories"
				onCreateOption={handleCreate}
				loading={loading}
			/>
		</div>
	);
};

export default CategoryInput;
