import { useRef, useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import {
	Avatar,
	Box,
	Button,
	ButtonBase,
	Divider,
	ListItemIcon,
	ListItemText,
	MenuItem,
	Popover,
	Typography,
} from "@material-ui/core";
import useAuth from "hooks/useAuth";
import CogIcon from "icons/Cog";
import UserIcon from "icons/User";
import formatNumber from "utils/formatNumber";

const AccountPopover = ({ type }) => {
	const anchorRef = useRef(null);
	const { activeUser, logout } = useAuth();
	const navigate = useNavigate();
	const { enqueueSnackbar } = useSnackbar();
	const [open, setOpen] = useState(false);

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleLogout = async () => {
		try {
			handleClose();
			await logout();
			navigate("/");
		} catch (err) {
			enqueueSnackbar("Unable to logout", {
				anchorOrigin: {
					horizontal: "right",
					vertical: "top",
				},
				variant: "error",
			});
		}
	};

	return (
		<>
			<Box
				component={ButtonBase}
				onClick={handleOpen}
				ref={anchorRef}
				sx={{
					alignItems: "center",
					display: "flex",
				}}>
				<Avatar
					src={activeUser?.client?.displayPic}
					sx={{
						height: 45,
						width: 45,
					}}
				/>
			</Box>
			<Popover
				anchorEl={anchorRef.current}
				anchorOrigin={{
					horizontal: "center",
					vertical: "bottom",
				}}
				getcontentanchorel={null}
				keepMounted
				onClose={handleClose}
				open={open}
				PaperProps={{
					sx: { width: 240 },
				}}>
				<Box sx={{ p: 2 }}>
					<Typography color="textPrimary" variant="subtitle2">
						{`${activeUser?.firstName} ${activeUser?.lastName}`}
					</Typography>
					<Typography color="textSecondary" variant="subtitle2">
						{activeUser?.title}
					</Typography>
				</Box>
				{activeUser?.programAccounts?.length ? (
					<>
						<Divider />
						<Box sx={{ p: 2 }}>
							<Typography color="textPrimary" variant="subtitle2">
								Your points balance
							</Typography>
							<br />
							{activeUser?.programAccounts?.map((p) => (
								<Typography color="textSecondary" variant="subtitle2">
									{p?.program?.name} - <b>{formatNumber(p?.balance, "points")}</b>
								</Typography>
							))}
						</Box>
					</>
				) : (
					<div />
				)}
				<Divider />
				<Box sx={{ mt: 2 }}>
					{type === "provider" && (
						<MenuItem component={RouterLink} to={`/${type}/settings/`}>
							<ListItemIcon>
								<UserIcon fontSize="small" />
							</ListItemIcon>
							<ListItemText
								primary={
									<Typography color="textPrimary" variant="subtitle2">
										Profile
									</Typography>
								}
							/>
						</MenuItem>
					)}
					<MenuItem component={RouterLink} to={`/${type}/settings/`}>
						<ListItemIcon>
							<CogIcon fontSize="small" />
						</ListItemIcon>
						<ListItemText
							primary={
								<Typography color="textPrimary" variant="subtitle2">
									Settings
								</Typography>
							}
						/>
					</MenuItem>
				</Box>
				<Box sx={{ p: 2 }}>
					<Button color="primary" fullWidth onClick={handleLogout} variant="outlined">
						Logout
					</Button>
				</Box>
			</Popover>
		</>
	);
};

export default AccountPopover;
