import { gql } from "@apollo/client";

const CreateCourseDate = gql`
	mutation Mutation($payload: createCourseDateInput) {
		createCourseDate(payload: $payload) {
			... on CourseDate {
				courseDateId
				providerId
				startTime
				endTime
				location
				updatedAt
				createdAt
				details
			}
			... on Error {
				message
			}
		}
	}
`;

export default CreateCourseDate;
