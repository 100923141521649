import SettingsPage from "pages/settings/SettingsPage";
import { lazy, Suspense } from "react";
import MemberReccomendations from "pages/member/reccomendations/MemberReccomendations";
import ViewOffering from "pages/marketplace/Offering/Offering";
import AuthGuard from "./components/common/AuthGuard";
import LoadingScreen from "./components/LoadingScreen/LoadingScreen";
import StandardLayout from "./components/layout/StandardLayout";

const Loadable = (Component) => (props) =>
	(
		<Suspense fallback={<LoadingScreen />}>
			<Component {...props} />
		</Suspense>
	);

// Authentication pages

const Login = Loadable(lazy(() => import("./pages/authentication/login/Login")));
const InvitePage = Loadable(lazy(() => import("./pages/authentication/invite/InvitePage")));
const PasswordRecovery = Loadable(lazy(() => import("./pages/authentication/passwordRecovery/PasswordRecovery")));
const PasswordReset = Loadable(lazy(() => import("./pages/authentication/passwordReset/component")));
const Register = Loadable(lazy(() => import("./pages/authentication/register/components")));
const VerifyCode = Loadable(lazy(() => import("./pages/authentication/verifyCode/VerifyCode")));

// Common Pages
const ProgramViewPage = Loadable(lazy(() => import("./pages/common/programView/ProgramViewPage")));

// Admin Pages
const AdminClientsList = Loadable(lazy(() => import("./pages/admin/clientsList/AdminClientsList")));
const AdminClientView = Loadable(lazy(() => import("./pages/admin/clientView/AdminClientView")));
const AdminActivityFeed = Loadable(lazy(() => import("./pages/admin/activityFeed/AdminActivityFeed")));
const AdminBillingList = Loadable(lazy(() => import("./pages/admin/billingList/AdminBillingList")));
const AdminFulfilmentQueue = Loadable(lazy(() => import("./pages/admin/fulfilmentQueue/AdminFulfilmentQueueList")));
const AdminHome = Loadable(lazy(() => import("./pages/admin/home/AdminHome")));
const AdminProvidersList = Loadable(lazy(() => import("./pages/admin/providersList/AdminProvidersList")));
const AdminUsersList = Loadable(lazy(() => import("./pages/admin/usersList/AdminUsersList")));
const AdminViewProvider = Loadable(lazy(() => import("./pages/admin/providerView/AdminViewProvider")));
const ViewUser = Loadable(lazy(() => import("./pages/common/userView/ViewUser")));

// Client Pages
const ClientDashboard = Loadable(lazy(() => import("./pages/client/dashboard/ClientDashboard")));
const ClientHome = Loadable(lazy(() => import("./pages/client/home/ClientHome")));
const ClientPeopleList = Loadable(lazy(() => import("./pages/client/usersList/ClientPeopleList")));
const ClientPlaylistLists = Loadable(lazy(() => import("./pages/client/playlistList/ClientPlaylistsList")));
const ClientProgramCreate = Loadable(lazy(() => import("./pages/client/programCreate/ClientProgramCreate")));
const ClientProgramsList = Loadable(lazy(() => import("./pages/client/programsList/ClientProgramsList")));

// Member pages
const MemberHome = Loadable(lazy(() => import("./pages/member/home/MemberHome")));
const MemberMyTeam = Loadable(lazy(() => import("./pages/member/myTeam/MemberMyTeam")));
const MemberProgramList = Loadable(lazy(() => import("./pages/member/programsList/MemberProgramsList")));

// Error pages

const AuthorizationRequired = Loadable(lazy(() => import("./pages/AuthorizationRequired")));
const NotFound = Loadable(lazy(() => import("./pages/NotFound")));
const ServerError = Loadable(lazy(() => import("./pages/ServerError")));

// Marketplace pages
const MarketplaceHome = Loadable(lazy(() => import("./pages/marketplace")));

// Provider pages
const ProviderHome = Loadable(lazy(() => import("./pages/provider/home/ProviderHome")));
const ProviderOfferings = Loadable(lazy(() => import("./pages/provider/offerings/ProviderOfferings")));
const ProviderEnagagements = Loadable(lazy(() => import("./pages/provider/engagements/ProviderEngagements")));
const ProviderEarnings = Loadable(lazy(() => import("./pages/provider/earnings/ProviderEarnings")));

// Other pages
const ViewPlaylistPage = Loadable(lazy(() => import("./pages/common/playlistView/ViewPlaylist")));

// const Contact = Loadable(lazy(() => import("./pages/Contact")));

const routes = [
	{
		path: "*",
		children: [
			{
				path: "/",
				element: (
					// <GuestGuard>
					<Login />
					// </GuestGuard>
				),
			},
			{
				path: "/invite",
				element: <InvitePage />,
			},
			{
				path: "401",
				element: <AuthorizationRequired />,
			},
			{
				path: "404",
				element: <NotFound />,
			},
			{
				path: "500",
				element: <ServerError />,
			},
			{
				path: "*",
				element: <NotFound />,
			},
			{
				path: "login-unguarded",
				element: <Login />,
			},
			{
				path: "password-recovery",
				element: <PasswordRecovery />,
			},
			{
				path: "password-reset",
				element: <PasswordReset />,
			},
			{
				path: "register",
				element: (
					// <GuestGuard>
					<Register />
					// </GuestGuard>
				),
			},
			{
				path: "register-unguarded",
				element: <Register />,
			},
			{
				path: "verify-code",
				element: <VerifyCode />,
			},
		],
	},

	// {
	// 	path: "contact",
	// 	element: <Contact />,
	// },
	// ADMIN ROUTES
	{
		path: "admin",
		element: (
			<AuthGuard>
				<StandardLayout type="admin" />
			</AuthGuard>
		),
		children: [
			{
				path: "/",
				element: <AdminHome />,
			},
			{
				path: "/settings",
				element: <SettingsPage />,
			},
			{
				path: "/users",
				children: [
					{
						path: "/",
						element: <AdminUsersList />,
					},
					{
						path: "/client/:cid",
						element: <AdminClientView goBack="users" />,
					},
					{
						path: "/:id",
						element: <ViewUser />,
					},
				],
			},
			{
				path: "/clients",
				children: [
					{
						path: "/",
						element: <AdminClientsList />,
					},
					{
						path: "/settings",
						element: <SettingsPage />,
					},
					{
						path: "/:cid",
						children: [
							{
								path: "/",
								element: <AdminClientView />,
							},
							{
								path: "/user/:id",
								element: <ViewUser goBack="client" />,
							},
							{
								path: "/program/:id",
								element: <ProgramViewPage type="admin" />,
							},
							{
								path: "/playlist/:pid",
								element: <ViewPlaylistPage />,
							},
						],
					},
				],
			},
			{
				path: "/billing",
				element: <AdminBillingList />,
			},
			{
				path: "/fulfilment",
				element: <AdminFulfilmentQueue />,
			},
			{
				path: "/feed",
				element: <AdminActivityFeed />,
			},
			{
				path: "/providers",
				children: [
					{
						path: "/",
						element: <AdminProvidersList />,
					},
					{
						path: "/:id",
						children: [
							{
								path: "/",
								element: <AdminViewProvider />,
							},

							{
								path: "/offering/:oid",
								element: <ViewOffering />,
							},
						],
					},
				],
			},
			{
				path: "/marketplace",
				children: [
					{
						path: "/",
						element: <MarketplaceHome type="admin" />,
					},
					{
						path: "/search/",
						element: <MarketplaceHome type="admin" />,
					},
					{
						path: "/:type/:oid",
						element: <MarketplaceHome type="admin" />,
					},
					{
						path: "/create",
						element: <ClientProgramCreate />,
					},
				],
			},
		],
	},
	// COACH ROUTES
	{
		path: "coach",
		element: (
			<AuthGuard>
				<StandardLayout type="coach" />
			</AuthGuard>
		),
		children: [
			{
				path: "/",
				element: <AdminHome />,
			},
			{
				path: "/settings",
				element: <SettingsPage />,
			},
		],
	},
	// MEMBER ROUTES
	{
		path: "member",
		element: (
			<AuthGuard>
				<StandardLayout type="member" />
			</AuthGuard>
		),
		children: [
			{
				path: "/",
				element: <MemberHome />,
			},
			{
				path: "/settings",
				element: <SettingsPage />,
			},
			{
				path: "/recommendations",
				element: <MemberReccomendations />,
			},
			{
				path: "/program",
				element: <MemberProgramList />,
			},
			{
				path: "/marketplace",
				children: [
					{
						path: "/",
						element: <MarketplaceHome type="member" />,
					},
					{
						path: "/search/",
						element: <MarketplaceHome type="member" />,
					},
					{
						path: "/offering/:oid",
						element: <MarketplaceHome type="member" />,
					},
					{
						path: "/create",
						element: <ClientProgramCreate />,
					},
				],
			},
			{
				path: "/team",
				element: <MemberMyTeam />,
			},
		],
	},
	// CLIENT ROUTES
	{
		path: "client",
		element: (
			<AuthGuard>
				<StandardLayout type="client" />
			</AuthGuard>
		),
		children: [
			{
				path: "/",
				element: <ClientHome />,
			},
			{
				path: "/settings",
				element: <SettingsPage />,
			},
			{
				path: "/dashboard",
				element: <ClientDashboard />,
			},
			{
				path: "/programs",
				children: [
					{
						path: "/",
						element: <ClientProgramsList />,
					},
					{
						path: "/:id",
						element: <ProgramViewPage type="client" />,
					},
				],
			},
			{
				path: "/people",
				element: <ClientPeopleList />,
			},
			{
				path: "/people/:id",
				element: <ViewUser />,
			},
			{
				path: "/marketplace",
				children: [
					{
						path: "/",
						element: <MarketplaceHome />,
					},
					{
						path: "/search/",
						element: <MarketplaceHome />,
					},
					{
						path: "/offering/:oid",
						element: <MarketplaceHome />,
					},
					{
						path: "/create",
						element: <ClientProgramCreate />,
					},
				],
			},
			{
				path: "/playlists",
				element: <ClientPlaylistLists />,
			},
			{
				path: "/playlists/:pid",
				element: <ViewPlaylistPage />,
			},
			{
				path: "/invoices",
				element: <ClientDashboard />,
			},
		],
	},
	// PROVIDER ROUTES
	{
		path: "provider",
		element: (
			<AuthGuard>
				<StandardLayout type="provider" />
			</AuthGuard>
		),
		children: [
			{
				path: "/",
				element: <ProviderHome />,
			},
			{
				path: "/offerings",
				element: <ProviderOfferings />,
			},
			{
				path: "/settings",
				element: <SettingsPage />,
			},
			{
				path: "/engagements",
				element: <ProviderEnagagements />,
			},

			{
				path: "/earnings",
				element: <ProviderEarnings />,
			},
			{
				path: "/marketplace",
				children: [
					{
						path: "/",
						element: <MarketplaceHome />,
					},
					{
						path: "/search/",
						element: <MarketplaceHome />,
					},
					{
						path: "/offering/:oid",
						element: <MarketplaceHome />,
					},
					{
						path: "/create",
						element: <ClientProgramCreate />,
					},
				],
			},
		],
	},
];

export default routes;
