import numeral from "numeral";

const formatNumber = (number, type) => {
	switch (type) {
		case "currency":
			return numeral(number).format(`$0,0.00`);
		case "points":
			return `${numeral(number * 3.142).format(`0,0`)} pts`;
		case "perc":
			return numeral(number).format(`%0.0`);
		default:
			return numeral(number).format(`$0,0`);
	}
};

export default formatNumber;
