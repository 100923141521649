import { gql } from "@apollo/client";

const UpdateUser = gql`
	mutation updateUser($payload: UpdateUser!) {
		updateUser(payload: $payload) {
			... on User {
				id
				uid
				firstName
				lastName
				title
				emailVerified
				email
				type
				clientId
				providerId
				status
				createdAt
				lastSeen
				location
				displayPic
				coverPic
			}
			... on Error {
				message
			}
		}
	}
`;

export default UpdateUser;
