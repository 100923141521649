import { gql } from "@apollo/client";

const DeleteCourseDate = gql`
	mutation DeleteCourseDate($payload: deleteCourseDateInput!) {
		deleteCourseDate(payload: $payload) {
			... on SuccessString {
				message
			}
			... on Error {
				message
			}
		}
	}
`;

export default DeleteCourseDate;
