/* eslint-disable jsx-a11y/anchor-is-valid */
import { Avatar, Box, Card, CardMedia, Divider, Grid, Link, Typography } from "@material-ui/core";
import locationsList from "utils/locationsList";
import StandardButton from "components/StandardButton/StandardButton";
import useAuth from "hooks/useAuth";
import { useLocation, useNavigate } from "react-router";
import deliveryTypes from "utils/deliveryTypes";
import formatDate from "utils/formatDate";
import formatNumber from "utils/formatNumber";
import Label from "components/Label/Label";
import getRandomCover from "utils/getRandomCover";

const SearchResult = ({ data, purchased }) => {
	if (!data) {
		return null;
	}
	const navigate = useNavigate();
	const location = useLocation();
	const { activeUser } = useAuth();

	const baseLink = `/${location.pathname.split("/")[1]}/marketplace/`;

	let linkText = "View Offering";
	if (data.type === "SERVICE") {
		linkText = "View Service";
	} else if (data.type === "COURSE") {
		linkText = "View Course";
	} else if (data.type === "COACH") {
		linkText = "View Package";
	} else if (data.type === "SUBSCRIPTION") {
		linkText = "View Subscription";
	} else if (data.type === "EVENT") {
		linkText = "View Event";
	}

	const link = `${baseLink}offering/${data.offeringId}`;

	return (
		<Card sx={{ width: 360 }}>
			<Box sx={{ p: 0 }}>
				<CardMedia
					image={data?.coverPic || getRandomCover()}
					sx={{
						backgroundColor: "background.default",
						height: 100,
					}}
				/>
				<Box
					sx={{
						alignItems: "center",
						display: "flex",
						py: 1.5,
						px: 2,
					}}>
					<Avatar sx={{ height: 60, width: 60 }} src={data?.provider?.displayPic} />
					<Box sx={{ ml: 2, height: 90 }} display="flex" justifyContent="center" flexDirection="column">
						<Link
							onClick={() => navigate(link)}
							color="textPrimary"
							sx={{ lineHeight: 1.3, cursor: "pointer", maxLines: 3 }}
							variant="h6">
							{data?.name}
						</Link>
						<Link
							onClick={() => navigate(`${baseLink}search/?providerId=${data?.provider?.providerId}`)}
							color="textSecondary">
							<Typography color="textSecondary" sx={{ cursor: "pointer" }} variant="subtitle2">
								{data?.provider?.name}
							</Typography>
						</Link>
					</Box>
				</Box>
			</Box>
			<Divider />
			<Box
				sx={{
					py: 1,
					px: 3,
				}}>
				<div
					style={{
						height: 60,
						textOverflow: "ellipsis",
						overflow: "hidden",
						width: "100%",
					}}>
					<Typography color="textSecondary" variant="body2">
						{data?.summary}
					</Typography>
				</div>
			</Box>
			<Box
				sx={{
					px: 3,
					py: 2,
				}}>
				<Grid alignItems="center" container justifyContent="space-between" spacing={3}>
					<Grid xs={6} item>
						<Typography color="textPrimary" variant="subtitle2">
							{deliveryTypes.find((d) => d.value === data?.delivery)?.label}
						</Typography>
						<Typography color="textSecondary" variant="body2">
							Delivery
						</Typography>
					</Grid>
					{data?.startTime && (
						<Grid xs={6} item>
							<Typography color="textPrimary" variant="subtitle2">
								{formatDate(data?.startTime)} - {formatDate(data?.endTime)}
							</Typography>
							<Typography color="textSecondary" variant="body2">
								When
							</Typography>
						</Grid>
					)}
					<Grid xs={6} item>
						<Typography color="textPrimary" variant="subtitle2">
							{data?.location ||
								locationsList.find((t) => t.value === data?.provider?.location)?.label ||
								""}
						</Typography>
						<Typography color="textSecondary" variant="body2">
							Location
						</Typography>
					</Grid>
					<Grid xs={6} item>
						<Typography color="textPrimary" variant="subtitle2">
							{activeUser.type === "MEMBER"
								? formatNumber(data?.salePrice, "points")
								: formatNumber(data?.salePrice, "currency")}
						</Typography>
						<Typography color="textSecondary" variant="body2">
							Price
						</Typography>
					</Grid>
				</Grid>
			</Box>
			<Divider />
			<Box
				flexDirection="row"
				sx={{
					alignItems: "center",
					justifyContent: "space-between",
					display: "flex",
					pl: 2,
					pr: 3,
					py: 1,
				}}>
				{purchased !== undefined && (
					<Label color={purchased ? "secondary" : "primary"}>
						{purchased ? "Purchased" : "Not Purchased"}
					</Label>
				)}
				<StandardButton buttonStyle="tertiary purple" text={linkText} onClick={() => navigate(link)} />
			</Box>
		</Card>
	);
};

export default SearchResult;
