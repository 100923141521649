import { useMutation } from "@apollo/client";
import { Container, Grid } from "@material-ui/core";
import { Download, Lock } from "@material-ui/icons";
import UpdateUser from "apollo/mutations/UpdateUser";
import EditableTable from "components/EditableTable/EditableTable";
import FormField from "components/FormField/FormField";
import PageHeader from "components/PageHeader/PageHeader";
import StandardButton from "components/StandardButton/StandardButton";
import showToast from "utils/showToast";
import AuthContext from "contexts/AuthContext";
import PencilAlt from "icons/PencilAlt";
import React, { useContext, useState } from "react";
import resetPassword from "utils/resetPassword";

const SettingsPage = () => {
	const [editMode, setEditMode] = useState(false);
	const [loading, setLoading] = useState(false);
	const { activeUser, refetchUser } = useContext(AuthContext);
	const [updateUser] = useMutation(UpdateUser);

	const handleSubmit = async (e) => {
		setLoading(true);
		e.persist();
		e.preventDefault();
		try {
			const payload = {
				variables: {
					payload: {
						uid: activeUser.uid,
						firstName: e.target.firstName.value,
						lastName: e.target.lastName.value,
						mobile: e.target.mobile.value,
						email: e.target.email.value,
						title: e.target.title.value,
					},
				},
			};
			await updateUser(payload);
			await refetchUser();
			setLoading(false);
			setEditMode(false);
		} catch (error) {
			setLoading(false);
			showToast("Something went wrong. Please try again.", "error");
		}
	};

	return (
		<form onSubmit={handleSubmit}>
			<PageHeader
				title="Settings"
				buttons={
					editMode ? (
						<StandardButton
							loading={loading}
							buttonStyle="secondary"
							text="Save Changes"
							icon={<Download />}
							type="submit"
							key="q32435gyresdfff"
						/>
					) : (
						<StandardButton
							buttonStyle="tertiary purple"
							text="Edit"
							type="button"
							key="q32435gyref"
							icon={<PencilAlt />}
							onClick={() => setEditMode(true)}
						/>
					)
				}
			/>
			<Container>
				<Grid container spacing={3}>
					<Grid xs={12} lg={8} item>
						<EditableTable
							title="Basic Info"
							editMode={editMode}
							footer={
								<StandardButton
									onClick={() => resetPassword(activeUser.email)}
									buttonStyle="tertiary"
									icon={<Lock />}
									text="Reset &amp; Send Password"
								/>
							}
							rows={[
								{
									label: "First Name",
									value: () => <div>{activeUser?.firstName || ""}</div>,
									field: () => (
										<FormField
											label="Name"
											type="text"
											name="firstName"
											defaultValue={activeUser.firstName}
											required
											key={activeUser.firstName}
										/>
									),
								},
								{
									label: "Last Name",
									value: () => <div>{activeUser?.lastName || "-"}</div>,
									field: () => (
										<FormField
											label="Last Name"
											type="text"
											name="lastName"
											defaultValue={activeUser.lastName}
											required
											key={activeUser.lastName}
										/>
									),
								},
								{
									label: "Title",
									value: () => <div>{activeUser?.title || "-"}</div>,
									field: () => (
										<FormField
											label="Title"
											name="title"
											type="text"
											defaultValue={activeUser.title}
											required
											key={activeUser.title}
										/>
									),
								},
								{
									label: "Mobile",
									value: () => <div>{activeUser?.mobile || "-"}</div>,
									field: () => (
										<FormField
											label="Mobile"
											name="mobile"
											type="text"
											defaultValue={activeUser.mobile}
											required
											key={activeUser.mobile}
										/>
									),
								},
								{
									label: "Email",
									value: () => <div>{activeUser?.email || "-"}</div>,
									field: () => (
										<FormField
											label="Email"
											name="email"
											type="email"
											defaultValue={activeUser.email}
											required
											key={activeUser.email}
										/>
									),
								},
							]}
						/>
					</Grid>
				</Grid>
			</Container>
		</form>
	);
};

export default SettingsPage;
