import { Box, Card, Typography } from "@material-ui/core";
import { experimentalStyled } from "@material-ui/core/styles";
import { Home } from "@material-ui/icons";
import Navbar from "components/Navbar/NavBar";
import StandardButton from "components/StandardButton/StandardButton";
import AuthContext from "contexts/AuthContext";
import ChartPie from "icons/ChartPie";
import ChartSquareBarIcon from "icons/ChartSquareBar";
import ShoppingBagIcon from "icons/ShoppingBag";
import Star from "icons/Star";
import Users from "icons/Users";
import { useContext, useState } from "react";
import { Outlet } from "react-router-dom";
import LogoBox from "./LogoBox";
import SideBar from "./SideBar";

const StandardLayoutRoot = experimentalStyled("div")(({ theme }) => ({
	backgroundColor: theme.palette.background.default,
	display: "flex",
	height: "100%",
	overflow: "hidden",
	width: "100%",
}));

const StandardLayoutWrapper = experimentalStyled("div")(({ theme }) => ({
	display: "flex",
	flex: "1 1 auto",
	overflow: "hidden",
	paddingTop: "64px",
	[theme.breakpoints.up("lg")]: {
		paddingLeft: "280px",
	},
}));

const StandardLayoutContainer = experimentalStyled("div")({
	display: "flex",
	flex: "1 1 auto",
	overflow: "hidden",
});

const StandardLayoutContent = experimentalStyled("div")({
	flex: "1 1 auto",
	height: "100%",
	overflow: "auto",
	position: "relative",
	WebkitOverflowScrolling: "touch",
});

const adminMenu = [
	{
		title: "Dashboard",
		items: [
			{
				title: "Home",
				path: "/admin",
				icon: <Home fontSize="small" />,
			},
			{
				title: "Fulfilment Queue",
				path: "/admin/fulfilment",
				icon: <ChartSquareBarIcon fontSize="small" />,
			},
		],
	},
	{
		title: "Marketplace",
		items: [
			{
				title: "Providers",
				path: "/admin/providers",
				icon: <Home fontSize="small" />,
			},
			{
				title: "Browse",
				path: "/admin/marketplace",
				icon: <ShoppingBagIcon fontSize="small" />,
			},
		],
	},
	{
		title: "Clients",
		items: [
			{
				title: "Clients",
				path: "/admin/clients",
				icon: <Home fontSize="small" />,
			},
			{
				title: "Users",
				path: "/admin/users",
				icon: <ChartSquareBarIcon fontSize="small" />,
			},
		],
	},
];

const clientMenu = [
	{
		title: "",
		items: [
			{
				title: "Home",
				path: "/client",
				icon: <Home fontSize="small" />,
			},
			{
				title: "Dashboard",
				path: "/client/dashboard",
				icon: <ChartSquareBarIcon fontSize="small" />,
			},
			{
				title: "Marketplace",
				path: "/client/marketplace",
				icon: <ShoppingBagIcon fontSize="small" />,
			},
			{
				title: "Programs",
				path: "/client/programs",
				icon: <ChartPie fontSize="small" />,
			},
			{
				title: "People",
				path: "/client/people",
				icon: <Users fontSize="small" />,
			},
			{
				title: "Playlists",
				path: "/client/playlists",
				icon: <Users fontSize="small" />,
			},
		],
	},
];

const coachMenu = [
	{
		title: "",
		items: [
			{
				title: "Home",
				path: "/client",
				icon: <Home fontSize="small" />,
			},
			{
				title: "Engagements",
				path: "/client/dashboard",
				icon: <ChartSquareBarIcon fontSize="small" />,
			},
			{
				title: "My Profile",
				path: "/client/marketplace",
				icon: <ShoppingBagIcon fontSize="small" />,
			},
			{
				title: "Offerings",
				path: "/client/programs",
				icon: <ChartPie fontSize="small" />,
			},
			{
				title: "Invoices",
				path: "/client/people",
				icon: <Users fontSize="small" />,
			},
			{
				title: "Marketplace",
				path: "/client/playlists",
				icon: <Users fontSize="small" />,
			},
		],
	},
];

const memberMenu = [
	{
		title: "",
		items: [
			{
				title: "Home",
				path: "/member",
				icon: <Home fontSize="small" />,
			},
			{
				title: "My Reccomendations",
				path: "/member/recommendations",
				icon: <Star fontSize="small" />,
			},
			{
				title: "My Program",
				path: "/member/program",
				icon: <ChartSquareBarIcon fontSize="small" />,
			},
			{
				title: "Browse",
				path: "/member/marketplace",
				icon: <ChartPie fontSize="small" />,
			},
			// {
			// 	title: "Reccomended",
			// 	path: "/client/people",
			// 	icon: <UsersIcon fontSize="small" />,
			// },
			// {
			// 	title: "Activity",
			// 	path: "/member/activity",
			// 	icon: <UsersIcon fontSize="small" />,
			// },
		],
	},
];

const providerMenu = [
	{
		title: "",
		items: [
			{
				title: "Home",
				path: "/provider",
				icon: <Home fontSize="small" />,
			},
			{
				title: "My Offerings",
				path: "/provider/offerings",
				icon: <ChartSquareBarIcon fontSize="small" />,
			},
			{
				title: "Engagements",
				path: "/provider/engagements",
				icon: <ShoppingBagIcon fontSize="small" />,
			},
			{
				title: "Earnings",
				path: "/provider/earnings",
				icon: <ChartPie fontSize="small" />,
			},
			{
				title: "Marketplace",
				path: "/provider/marketplace",
				icon: <Users fontSize="small" />,
			},
		],
	},
];

const StandardLayout = ({ type = "client" }) => {
	const [isSidebarMobileOpen, setIsSidebarMobileOpen] = useState(false);
	const { activeUser, cachedUser, stopImpersonation } = useContext(AuthContext);
	// const [updateUser] = useMutation(UpdateUser);

	// useEffect(() => {
	// 	if (activeUser.uid) {
	// 		updateUser({
	// 			variables: {
	// 				payload: {
	// 					uid: activeUser.uid,
	// 					emailVerified: true,
	// 				},
	// 			},
	// 		});
	// 	}
	// }, [activeUser]);

	return (
		<>
			<StandardLayoutRoot>
				{/* RENDERS THE CORRECT NAV FOR USER TYPE */}
				<Navbar type={type} onSidebarMobileOpen={() => setIsSidebarMobileOpen(true)} />

				{(type === "client" || type === "member") && (
					<SideBar
						menuArr={clientMenu}
						onMobileClose={() => setIsSidebarMobileOpen(false)}
						openMobile={isSidebarMobileOpen}
						topBox={
							<LogoBox
								firstLine={activeUser?.clientAccount?.name}
								imageUrl={activeUser?.clientAccount?.displayPic}
							/>
						}
					/>
				)}

				{type === "admin" && (
					<SideBar
						menuArr={adminMenu}
						onMobileClose={() => setIsSidebarMobileOpen(false)}
						openMobile={isSidebarMobileOpen}
					/>
				)}

				{type === "coach" && (
					<SideBar
						topBox={
							<LogoBox
								firstLine={`${activeUser.firstName} ${activeUser.lastName}`}
								secondLine={activeUser.title}
								imageUrl={activeUser.displayPic}
							/>
						}
						menuArr={coachMenu}
						onMobileClose={() => setIsSidebarMobileOpen(false)}
						openMobile={isSidebarMobileOpen}
					/>
				)}

				{type === "member" && (
					<SideBar
						topBox={
							<LogoBox
								firstLine={activeUser?.clientAccount?.name}
								imageUrl={activeUser?.clientAccount?.displayPic}
								secondLine="Sponsored by"
							/>
						}
						menuArr={memberMenu}
						onMobileClose={() => setIsSidebarMobileOpen(false)}
						openMobile={isSidebarMobileOpen}
					/>
				)}

				{type === "provider" && (
					<SideBar
						topBox={
							<LogoBox
								firstLine={`${activeUser.firstName} ${activeUser.lastName}`}
								secondLine={activeUser.title}
								imageUrl={activeUser.displayPic}
							/>
						}
						menuArr={providerMenu}
						onMobileClose={() => setIsSidebarMobileOpen(false)}
						openMobile={isSidebarMobileOpen}
					/>
				)}

				<StandardLayoutWrapper>
					<StandardLayoutContainer>
						<StandardLayoutContent>
							{Object.keys(cachedUser).length > 0 && (
								<Box sx={{ p: 2 }}>
									<Card sx={{ p: 1 }}>
										<Box display="flex" alignItems="center" justifyContent="center">
											<Typography color="textPrimary" variant="h6" sx={{ mr: 3 }}>
												You are impersonating a user: {activeUser.firstName}{" "}
												{activeUser.lastName}
											</Typography>
											<StandardButton
												text="Stop Impersonation"
												buttonStyle="primary"
												onClick={() => stopImpersonation()}
											/>
										</Box>
									</Card>
								</Box>
							)}
							<Outlet />
						</StandardLayoutContent>
					</StandardLayoutContainer>
				</StandardLayoutWrapper>
			</StandardLayoutRoot>
		</>
	);
};

export default StandardLayout;
