import { useEffect } from "react";
import NProgress from "nprogress";
import { Box, CircularProgress } from "@material-ui/core";

const LoadingScreen = () => {
	useEffect(() => {
		NProgress.start();

		return () => {
			NProgress.done();
		};
	}, []);

	return (
		<Box
			sx={{
				alignItems: "center",
				backgroundColor: "background.default",
				display: "flex",
				flexDirection: "column",
				height: "100%",
				justifyContent: "center",
				width: "100%",
			}}>
			<CircularProgress size={50} />
		</Box>
	);
};

export default LoadingScreen;
