import { gql } from "@apollo/client";

const GetRecommendations = gql`
	query Query($payload: getRecommendationsInput) {
		getRecommendations(payload: $payload) {
			... on Offerings {
				coachPackages {
					name
					coverPic
					costPrice
					salePrice
					categories
					description
					delivery
					coachPackageId
					summary
					provider {
						providerId
						name
						title
						location
						displayPic
					}
				}
				courses {
					courseId
					categories
					name
					description
					delivery
					coverPic
					summary
					provider {
						providerId
						displayPic
						location
						name
						title
					}
					salePrice
					duration
					durationMulti
					durationIncrement
				}
				events {
					eventId
					name
					description
					location
					categories
					delivery
					coverPic
					summary
					provider {
						providerId
						displayPic
						name
						title
					}
					salePrice
					startTime
					endTime
				}
				services {
					serviceId
					name
					description
					categories
					delivery
					coverPic
					summary
					provider {
						providerId
						name
						displayPic
						title
						location
					}
					salePrice
				}
				subscriptions {
					subscriptionId
					name
					description
					salePrice
					categories
					delivery
					coverPic
					summary
					provider {
						providerId
						name
						providerId
						displayPic
						title
						location
					}
				}
				categories {
					label
					value
				}
			}
			... on Error {
				message
			}
		}
	}
`;

export default GetRecommendations;
