import { gql } from "@apollo/client";

const PurchaseOffering = gql`
	mutation Mutation($payload: purchaseOfferingInput!) {
		purchaseOffering(payload: $payload) {
			... on SuccessString {
				message
			}
			... on Error {
				message
			}
		}
	}
`;

export default PurchaseOffering;
