/* eslint-disable react/jsx-filename-extension */
/* eslint-disable no-undef */
import StyledEngineProvider from "@material-ui/core/StyledEngineProvider";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import "nprogress/nprogress.css";
import { StrictMode } from "react";
import { CookiesProvider } from "react-cookie";
import ReactDOM from "react-dom";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";
import { ApolloClientProvider } from "./apollo/apolloClientProvider";
import App from "./App";
import { AuthProvider } from "./contexts/AuthContext";
import { SettingsProvider } from "./contexts/SettingsContext";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorker from "./serviceWorker";
import "./fonts/styles.css";
import "./theme/toastStyles.css";

ReactDOM.render(
	<StrictMode>
		<ApolloClientProvider>
			<HelmetProvider>
				<BrowserRouter>
					<AuthProvider>
						<CookiesProvider>
							<StyledEngineProvider injectFirst>
								<LocalizationProvider dateAdapter={AdapterDateFns}>
									<SettingsProvider>
										<App />
									</SettingsProvider>
								</LocalizationProvider>
							</StyledEngineProvider>
						</CookiesProvider>
					</AuthProvider>
				</BrowserRouter>
			</HelmetProvider>
		</ApolloClientProvider>
	</StrictMode>,
	document.getElementById("root")
);

// If you want to enable client cache, register instead.
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
