import { gql } from "@apollo/client";

const GetOffering = gql`
	query Query($payload: getOfferingInput!) {
		getOffering(payload: $payload) {
			... on Offering {
				offeringId
				providerId
				provider {
					providerId
					name
					type
					title
					displayPic
				}
				type
				name
				availability
				description
				costPrice
				salePrice
				delivery
				duration
				workload
				categories
				summary

				coverPic
				data
				courseDates {
					courseDateId
					name
					details
					startTime
					endTime
					location
					updatedAt
					createdAt
				}
			}
		}
	}
`;

export default GetOffering;
