import { useQuery } from "@apollo/client";
import { Card, Typography } from "@material-ui/core";
import PageContainer from "components/PageContainer/PageContainer";
import PageHeader from "components/PageHeader/PageHeader";
import StandardButton from "components/StandardButton/StandardButton";
import AuthContext from "contexts/AuthContext";
import SearchResultsPage from "pages/marketplace/SearchResults";
import React, { useContext } from "react";
import GetRecommendations from "../../../apollo/queries/GetRecommendations";

const MemberReccomendations = () => {
	const { activeUser, activeProgram } = useContext(AuthContext);
	const { data } = useQuery(GetRecommendations, {
		notifyOnNetworkStatusChange: true,
		variables: { payload: { memberId: activeUser?.uid, programId: activeProgram } },
		skip: !activeUser?.uid || !activeProgram,
	});

	const reccomendationsObj = data?.getRecommendations || null;

	return (
		<>
			<PageHeader title="My Reccomendations" />
			<PageContainer>
				<Card sx={{ p: 3, mb: 0 }}>
					<Typography sx={{ mb: 2 }} color="textPrimary" variant="subtitle2">
						Below you can find a curated selection of services and offerings that the careerbase team has
						put down for you.
					</Typography>

					<Typography sx={{ mb: 2 }} color="textPrimary" variant="subtitle2">
						Browse the selection and purchase individually, or purchase all of the suggestions using the
						button below.
					</Typography>
					<StandardButton text="Purchase all offerings below" />
				</Card>
			</PageContainer>
			<SearchResultsPage offerings={reccomendationsObj} />
			<br />
			<br />
		</>
	);
};

export default MemberReccomendations;
