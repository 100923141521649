import { Box, Container, Grid, Typography } from "@material-ui/core";
import React from "react";

const PageHeader = ({ title, buttons, subheader }) => {
	if (subheader) {
		return (
			<>
				<Box
					width={1}
					sx={{
						alignItems: "center",
						display: "flex",
						flexWrap: "wrap",
						justifyContent: "space-between",
						mb: 3,
					}}>
					{title ? (
						<Typography
							color="textPrimary"
							sx={{
								position: "relative",
								"&:after": {
									backgroundColor: "primary.main",
									bottom: "-8px",
									content: '" "',
									height: "3px",
									left: 0,
									position: "absolute",
									width: "48px",
								},
							}}
							variant="h6">
							{title}
						</Typography>
					) : (
						<Box />
					)}
					<Box
						sx={{
							alignItems: "center",
							display: "flex",
						}}>
						{buttons}
					</Box>
				</Box>
			</>
		);
	}
	return (
		<Container sx={{ mt: 4, mb: 3 }}>
			<Grid container justifyContent="space-between" alignItems="center" spacing={3}>
				<Grid item>
					<Typography color="textPrimary" variant="h5">
						{title}
					</Typography>
				</Grid>
				<Grid item>{buttons}</Grid>
			</Grid>
		</Container>
	);
};

export default PageHeader;
