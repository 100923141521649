import { useQuery } from "@apollo/client";
import { Box, Card, Container, Divider, Grid, Typography } from "@material-ui/core";
import GetProvider from "apollo/queries/GetProvider";
import EditableTable from "components/EditableTable/EditableTable";
import ImageHeader from "components/ImageHeader/ImageHeader";
import useQueryParams from "hooks/useQueryParams";
import { Helmet } from "react-helmet-async";
import formatDate from "utils/formatDate";
import locationsList from "utils/locationsList";
import parseHtml from "utils/parseHtml";

const ViewStoreProvider = () => {
	// Get the query params
	const sp = useQueryParams();
	const providerId = sp.get("providerId");
	// eslint-disable-next-line no-unused-vars
	const { data, refetch, loading } = useQuery(GetProvider, {
		notifyOnNetworkStatusChange: true,
		variables: { payload: { providerId } },
		skip: !providerId,
	});
	const pageData = data?.getProvider || {};

	return (
		<>
			<Helmet>
				<title>Careerbase | Provider View</title>
			</Helmet>
			<ImageHeader
				refetch={refetch}
				title={pageData.name}
				subtitle={pageData.title}
				coverPic={pageData.coverPic}
				displayPic={pageData.displayPic}
				disableDisplayPic
				disableCoverPic
			/>
			<Container maxWidth="lg">
				<Box>
					<Grid container spacing={3}>
						<Grid item lg={8} md={6} xs={12}>
							<Card>
								<Box sx={{ p: 2 }}>
									<Typography color="textPrimary" variant="h6">
										About
									</Typography>
								</Box>

								<Divider />
								<Box sx={{ px: 2, pt: 1, pb: 2 }}>
									<Typography>{parseHtml(pageData?.description)}</Typography>
								</Box>
							</Card>
						</Grid>
						<Grid item lg={4} md={6} xs={12}>
							<EditableTable
								title="Details"
								editMode={false}
								disabled={loading}
								size="sm"
								rows={[
									{
										label: "Location",
										value: () => (
											<div>
												{locationsList?.find((loc) => loc.value === pageData.location)?.label}
											</div>
										),
										field: null,
									},
									{
										label: "Type",
										value: () => <div>{pageData?.type?.map((c) => `${c} `)}</div>,
										field: null,
									},
									{
										label: "Created At",
										value: () => <div>{formatDate(pageData.createdAt) || "-"}</div>,
										field: null,
									},
								]}
							/>
						</Grid>
					</Grid>
				</Box>
			</Container>
		</>
	);
};

export default ViewStoreProvider;
