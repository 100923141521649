import { gql } from "@apollo/client";

const CreateCategory = gql`
	mutation CreateCategory($payload: createCategoryInput) {
		createCategory(payload: $payload) {
			... on Categories {
				categoryId
				value
				label
			}
			... on Error {
				message
			}
		}
	}
`;

export default CreateCategory;
