/* eslint-disable no-unused-vars */
import FormField from "components/FormField/FormField";
import StandardButton from "components/StandardButton/StandardButton";
import showToast from "utils/showToast";
import { useState } from "react";
import ErrorMessage from "../common/ErrorMessage";

const { Dialog, Card, CardHeader, CardContent, Typography, Box } = require("@material-ui/core");
const { Close } = require("@material-ui/icons");

const ConfirmationModal = ({
	open,
	onClose,
	onConfirm,
	message = false,
	challenge,
	payload = false,
	refetch = false,
	onSuccess = false,
	buttonText = "Delete",
	modalTitle = "Confirm Deletion",
}) => {
	const [error, setError] = useState("");
	const [text, setText] = useState("");
	const [loading, setLoading] = useState(false);

	const handleConfirm = async (e) => {
		e.preventDefault();
		e.persist();
		try {
			setLoading(true);
			if (challenge) {
				if (text !== challenge) throw new Error("Challenge phrase is incorrect, please update field.");
			}
			await onConfirm(payload || null);
			if (refetch) await refetch();
			showToast(onSuccess || "Successfully removed items.", "success");
			onClose();
			setText("");
			setError("");
			setLoading(false);
		} catch (err) {
			setError(err.message);
			setLoading(false);
		}
	};

	const onTextChange = (val) => {
		if (error) setError("");
		setText(val.target.value.toUpperCase());
	};

	const closeModal = () => {
		setError("");
		setText("");
		onClose();
	};

	const defaultMessage = (
		<Typography sx={{ mb: 2 }} variant="body1">
			Are you sure you want to delete this item(s)? This cannot be undone.
		</Typography>
	);

	return (
		<Dialog open={open}>
			<Card
				elevation={3}
				sx={{
					mx: "auto",
				}}>
				<CardHeader title={modalTitle} action={<Close sx={{ cursor: "pointer" }} onClick={closeModal} />} />
				<CardContent>
					{message || defaultMessage}

					{challenge && (
						<FormField
							placeholder={challenge}
							type="text"
							value={text}
							onChange={(val) => onTextChange(val)}
						/>
					)}
					<Box sx={{ pt: 2 }} display="flex" flexDirection="row-reverse">
						<StandardButton
							loading={loading}
							disabled={text !== challenge}
							text={buttonText}
							onClick={handleConfirm}
						/>
						<StandardButton disabled={loading} text="Cancel" buttonStyle="secondary" onClick={closeModal} />
					</Box>

					<ErrorMessage message={error} />
				</CardContent>
			</Card>
		</Dialog>
	);
};

export default ConfirmationModal;
