import { gql } from "@apollo/client";

const GetPlaylists = gql`
	query GetPlaylists($payload: getPlaylistsInput) {
		getPlaylists(payload: $payload) {
			... on Playlist {
				offeringCount
				name
				createdAt
				updatedAt
				playlistId
			}
			... on Error {
				message
			}
		}
	}
`;

export default GetPlaylists;
