import { Card, CardHeader, Divider, Table, TableBody, TableCell, TableRow, Typography } from "@material-ui/core";
import Hidden from "components/common/Hidden";
import React from "react";

// Use this editable table for all profile editing etc
// title = title for top of card
// editMode = if true, show form fields instead of text
// rows = the row data. Look at example of usage in app
// footer = optional jsx for footer of table. Can include buttons etc
const EditableTable = ({ title, editMode, rows, footer, size }) => {
	if (!rows || rows.length === 0) {
		throw new Error("EditableTable: rows must be defined");
	}

	const smallVersion = (smallRows) => (
		<>
			{smallRows.map((row) => {
				const FieldCell = () => (row.field ? row.field({ row }) : null);
				const ValueCell = () => (row.value ? row.value({ row }) : null);

				return (
					<TableRow>
						{editMode ? (
							<>
								{row.field ? (
									<TableCell>
										<FieldCell />
									</TableCell>
								) : (
									<>
										<TableCell>
											<Typography color="textPrimary" variant="subtitle2">
												{row.label}
											</Typography>
										</TableCell>
										<TableCell>
											<Typography color="textSecondary" variant="body2">
												<ValueCell row={row} />
											</Typography>
										</TableCell>
									</>
								)}
							</>
						) : (
							<>
								<TableCell>
									<Typography color="textPrimary" variant="subtitle2">
										{row.label}
									</Typography>
								</TableCell>
								<TableCell>
									<Typography color="textSecondary" variant="body2">
										<ValueCell row={row} />
									</Typography>
								</TableCell>
							</>
						)}
					</TableRow>
				);
			})}
		</>
	);

	return (
		<Card>
			<CardHeader title={title || "Info"} />
			<Divider />
			<Table>
				<TableBody>
					{size !== "sm" ? (
						<>
							{/* The large screen one */}
							<Hidden rules="smDown">
								{rows.map((row, i) => {
									const FieldCellOne = () => (row.field ? row.field({ row }) : null);
									const ValueCellOne = () => (row.value ? row.value({ row }) : null);

									const FieldCellTwo = () =>
										rows[i + 1]?.field ? rows[i + 1]?.field({ row }) : null;
									const ValueCellTwo = () =>
										rows[i + 1]?.value ? rows[i + 1]?.value({ row }) : null;

									// If even number
									if (i % 2 === 0) {
										return (
											<TableRow>
												{editMode ? (
													<>
														{row.field ? (
															<TableCell>
																<FieldCellOne />{" "}
															</TableCell>
														) : (
															<>
																<TableCell>
																	<Typography color="textPrimary" variant="subtitle2">
																		{row.label}
																	</Typography>

																	<Typography color="textSecondary" variant="body2">
																		<ValueCellOne row={row} />
																	</Typography>
																</TableCell>
															</>
														)}

														<TableCell>
															{rows[i + 1]?.value ? (
																<FieldCellTwo />
															) : (
																<TableCell>
																	<Typography color="textPrimary" variant="subtitle2">
																		{rows[i + 1]?.label}
																	</Typography>

																	<Typography color="textSecondary" variant="body2">
																		<ValueCellTwo row={row} />
																	</Typography>
																</TableCell>
															)}
														</TableCell>
													</>
												) : (
													<>
														<TableCell>
															<Typography color="textPrimary" variant="subtitle2">
																{row.label}
															</Typography>
														</TableCell>

														<TableCell>
															<Typography color="textSecondary" variant="body2">
																<ValueCellOne row={row} />
															</Typography>
														</TableCell>

														<TableCell>
															<Typography color="textPrimary" variant="subtitle2">
																{rows[i + 1]?.label}
															</Typography>
														</TableCell>

														<TableCell>
															<Typography color="textSecondary" variant="body2">
																<ValueCellTwo row={row} />
															</Typography>
														</TableCell>
													</>
												)}
											</TableRow>
										);
									}
									return null;
								})}
							</Hidden>

							{/* Small Screen one */}
							<Hidden rules="smUp">{smallVersion(rows)}</Hidden>
						</>
					) : (
						smallVersion(rows)
					)}
				</TableBody>
			</Table>
			{/* Optional footer JSX */}
			{footer}
		</Card>
	);
};

export default EditableTable;
