import { getUserString } from "apollo/queries/GetUser";
import { graphqlEndpoint } from "../../apollo/apolloClientProvider";

const authGetUser = async (uid, token) => {
	try {
		// eslint-disable-next-line no-undef
		const request = await fetch(graphqlEndpoint, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				authorization: token,
			},
			body: JSON.stringify({
				query: getUserString,
				variables: {
					payload: {
						uid,
					},
				},
			}),
		}).then((res) => res.json());

		return request.data.getUser;
	} catch (error) {
		// console.log(error);
		throw new Error("Could not get users details");
	}
};

export default authGetUser;
