const { Box, Typography } = require("@material-ui/core");
const { Error } = require("@material-ui/icons");

const ErrorMessage = ({ message }) => (
	<>
		{message ? (
			<Box sx={{ mt: 1 }} display="flex" flexDirection="row" alignItems="center">
				<Error color="secondary" />
				<Typography sx={{ ml: 1 }} variant="body2" color="secondary">
					{message}
				</Typography>
			</Box>
		) : null}
	</>
);

export default ErrorMessage;
