import { Container, Grid } from "@material-ui/core";
import React from "react";

const PageContainer = ({ children }) => (
	<Container>
		<Grid container spacing={3}>
			<Grid xs={12} item>
				{children}
			</Grid>
		</Grid>
	</Container>
);

export default PageContainer;
