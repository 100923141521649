import showToast from "utils/showToast";
import firebase from "../config/firebase";

const resetPassword = (email) => {
	// Call this function to send password reset email

	firebase.auth().sendPasswordResetEmail(email);
	showToast("Password reset email sent");
};

export default resetPassword;
