import { Button, CircularProgress, Fab } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import React from "react";

// This is the standard button which must be used throughout app
const StandardButton = ({ text, onClick, buttonStyle, icon, disabled, loading, type, sx }) => {
	// THREE BUTTON STYLES
	// primary
	// secondary
	// tertiary
	// tertiary purple
	// back

	// If it's a back button
	if (buttonStyle === "back") {
		return (
			<Fab onClick={onClick} size="small" sx={{ cursor: "pointer" }}>
				{loading ? <CircularProgress size={20} color="inherit" /> : <ArrowBack />}
			</Fab>
		);
	}

	// Decide on button styles
	let buttonStyleString = "secondary";
	let variantStyleString = "contained";
	if (buttonStyle === "secondary") {
		variantStyleString = "outlined";
	} else if (buttonStyle === "tertiary") {
		buttonStyleString = "inherit";
		variantStyleString = "text";
	} else if (buttonStyle === "tertiary purple") {
		buttonStyleString = "primary";
		variantStyleString = "text";
	}

	// Otherwise, its your regular button
	return (
		<Button
			disabled={disabled || loading}
			onClick={onClick}
			type={type || "button"}
			color={buttonStyleString}
			endIcon={loading ? <CircularProgress size={20} color="inherit" /> : icon}
			sx={{ m: 1, ...sx }}
			variant={variantStyleString}>
			{text}
		</Button>
	);
};

export default StandardButton;
