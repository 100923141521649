/* eslint-disable consistent-return */
import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache, from, Observable } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { useCookies } from "react-cookie";
import { onError } from "@apollo/client/link/error";
import firebase from "../config/firebase";

export const graphqlEndpoint = "http://localhost:8080/graphql";

export const ApolloClientProvider = ({ children }) => {
	const [cookies, setCookie] = useCookies(["token"]);
	const token = cookies.token || "";

	const httpLink = createHttpLink({
		uri: graphqlEndpoint,
		credentials: "include",
	});

	const getNewToken = async () =>
		firebase
			.auth()
			.currentUser.getIdToken(/* forceRefresh */ true)
			.then((idToken) => {
				setCookie("token", idToken, { path: "/" });
				return idToken;
			});

	const promiseToObservable = (promise) =>
		new Observable((subscriber) => {
			promise.then(
				(value) => {
					if (subscriber.closed) {
						return;
					}
					subscriber.next(value);
					subscriber.complete();
				},
				(err) => {
					subscriber.error(err);
				}
			);
		});

	const errorHandler = onError(({ graphQLErrors, networkError, operation, forward }) => {
		if (graphQLErrors) {
			graphQLErrors.forEach((err) => {
				// eslint-disable-next-line no-console
				console.log(`[RAW error graphql]: ${err}`);
				const oldHeaders = operation.getContext().headers;
				const promise = getNewToken();
				return promiseToObservable(promise).flatMap((newToken) => {
					operation.setContext({
						headers: {
							...oldHeaders,
							authorization: `${newToken}`,
						},
					});
					return forward(operation);
				});
			});
		}
		if (networkError) {
			// eslint-disable-next-line no-console
			console.log(`[Network error]: ${networkError}`);
		}
	});

	const authLink = setContext((_, { headers }) => ({
		headers: {
			...headers,
			authorization: token,
		},
	}));

	const apollo = new ApolloClient({
		link: from([errorHandler, authLink.concat(httpLink)]),
		cache: new InMemoryCache(),
	});

	return <ApolloProvider client={apollo}>{children}</ApolloProvider>;
};
