import { gql } from "@apollo/client";

const UpdateCourseDate = gql`
	mutation UpdateCourseDateMutation($payload: updateCourseDateInput) {
		updateCourseDate(payload: $payload) {
			... on CourseDate {
				courseDateId
			}
			... on Error {
				message
			}
		}
	}
`;

export default UpdateCourseDate;
