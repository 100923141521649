import { useMutation } from "@apollo/client";
import {
	Box,
	Card,
	CardContent,
	CardHeader,
	CircularProgress,
	Dialog,
	Divider,
	Grid,
	TextField,
	Typography,
} from "@material-ui/core";
import { Add, ArrowForward, Close, Edit } from "@material-ui/icons";
import { MobileDateRangePicker } from "@material-ui/lab";
import CreateCourseDate from "apollo/mutations/CreateCourseDate";
import DeleteCourseDate from "apollo/mutations/DeleteCourseDate";
import UpdateCourseDate from "apollo/mutations/UpdateCourseDate";
import DataTable from "components/DataTable/DataTable";
import FormField from "components/FormField/FormField";
import StandardButton from "components/StandardButton/StandardButton";
import showToast from "utils/showToast";
import moment from "moment";
import { useState } from "react";
import formatDate from "utils/formatDate";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";

const CourseDateTable = ({ dates, refetch, providerId, offeringId, editable }) => {
	const [status, setStatus] = useState("init");
	const [courseDateId, setCourseDateId] = useState("");
	const [modal, setModal] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const initState = {
		inputDates: [new Date(), new Date()],
		location: "",
		details: "",
	};
	const [createCourseDate] = useMutation(CreateCourseDate);
	const [updateCourseDate] = useMutation(UpdateCourseDate);
	const [deleteCourseDate] = useMutation(DeleteCourseDate);
	const [delConfirm, setDelConfirm] = useState({
		open: false,
		message: "",
		payload: "",
		onSuccess: "",
	});
	const [inputs, setInputs] = useState(initState);

	const toggleEdit = (id) => {
		if (modal) {
			setModal(false);
			setInputs(initState);
			setStatus("init");
		} else if (id) {
			setCourseDateId(id);
			const x = dates.find((z) => z.courseDateId === id);
			const init = {
				inputDates: [new Date(x.startTime), new Date(x.endTime)],
				location: x.location,
				details: x.details,
			};
			setInputs(init);
			setStatus("init");
			setModal("edit");
		}
	};

	const columns = [
		{
			label: "Dates",
			cell: ({ row }) => (
				<Box display="flex" flexDirection="row">
					<div>{formatDate(row.startTime)}</div>
					<ArrowForward sx={{ fontSize: 16, mx: 2 }} />
					<div>{formatDate(row.endTime)}</div>
				</Box>
			),
		},
		{ label: "Location", selector: "location" },
	];

	if (editable)
		columns.push({
			label: "Actions",
			cell: ({ row }) => (
				<div>
					{" "}
					<StandardButton
						onClick={() => toggleEdit(row.courseDateId)}
						icon={<Edit fontSize="small" />}
						buttonStyle="tertiary"
					/>
				</div>
			),
		});

	const toggleModal = () => {
		setModal(!modal);
		setInputs(initState);
		setStatus("init");
	};

	const toggleCreate = () => {
		if (modal) {
			setModal(false);
			setInputs(initState);
			setStatus("init");
		} else {
			setModal("create");
			setInputs(initState);
			setStatus("init");
		}
	};

	const CreateButton = () => (
		<StandardButton onClick={toggleCreate} buttonStyle="tertiary purple" icon={<Add />} text="New Course Date" />
	);

	const handleCreate = async () => {
		try {
			setStatus("loading");
			if (errorMessage) setErrorMessage("");
			const payload = {
				variables: {
					payload: {
						providerId,
						offeringId,
						startTime: moment(inputs.inputDates[0]).format(),
						endTime: moment(inputs.inputDates[1]).format(),
						location: inputs.location,
						details: inputs.details,
					},
				},
			};
			const create = await createCourseDate(payload);
			if (create.data.createCourseDate.__typename === "Error")
				throw new Error(create.data.createCourseDate.message);
			await refetch();
			setStatus("fin");
		} catch (error) {
			showToast(error.message || "Could not create course date", "error");
			setStatus("init");
		}
	};

	const handleUpdate = async () => {
		try {
			setStatus("loading");
			if (errorMessage) setErrorMessage("");
			const payload = {
				variables: {
					payload: {
						courseDateId,
						startTime: moment(inputs.inputDates[0]).format(),
						endTime: moment(inputs.inputDates[1]).format(),
						location: inputs.location,
						details: inputs.details,
					},
				},
			};
			const create = await updateCourseDate(payload);
			if (create.data.updateCourseDate.__typename === "Error")
				throw new Error(create.data.updateCourseDate.message);
			await refetch();
			setModal(false);
			toggleEdit();
		} catch (error) {
			showToast(error.message || "Could not create course date", "error");
			setStatus("init");
		}
	};

	const resetForm = () => {
		setInputs(initState);
		setStatus("init");
	};

	// Delete course dates
	const bulkDeleteOfferings = (rows) => {
		const ids = rows.map((row) => row.courseDateId);
		setDelConfirm({
			open: true,
			message: (
				<Typography sx={{ mb: 2 }} variant="body1">
					Are you sure you want to delete {ids.length} {ids.length > 1 ? "course dates" : "course date"}? This
					can&apos;t be undone.
				</Typography>
			),
			payload: {
				variables: { payload: { courseDateId: ids } },
			},
			onSuccess: `Successfully deleted ${ids.length} ${ids.length > 1 ? "course dates" : "course date"}.`,
		});
	};

	// Bulk actions array to feed to table
	const bulkActions = [{ name: "Delete", function: bulkDeleteOfferings }];

	const toggleDelModal = () => setDelConfirm({ ...delConfirm, open: !delConfirm.open });

	return (
		<Card>
			<ConfirmationModal
				open={delConfirm.open}
				onClose={toggleDelModal}
				message={delConfirm.message}
				onSuccess={delConfirm.onSuccess}
				onConfirm={deleteCourseDate}
				payload={delConfirm.payload}
				refetch={refetch}
				challenge="DELETE"
			/>
			<DataTable
				bulkActions={editable ? bulkActions : null}
				columns={columns}
				data={dates || []}
				title="Course Dates"
				skeleton={false}
				action={editable ? <CreateButton /> : null}
			/>
			<Dialog maxWidth="sm" open={modal}>
				<Card>
					<CardHeader
						title="New Course Date"
						action={<StandardButton onClick={toggleModal} buttonStyle="tertiary" icon={<Close />} />}
					/>
					<Divider />
					<CardContent>
						<Grid container spacing={3}>
							<Grid item xs={12}>
								<MobileDateRangePicker
									disabled={status !== "init"}
									onChange={(newDate) => setInputs({ ...inputs, inputDates: newDate })}
									label="En"
									renderInput={(startProps, endProps) => (
										<Grid container spacing={3}>
											<Grid item xs={6}>
												{" "}
												<TextField
													disabled={status !== "init"}
													fullWidth
													variant="outlined"
													{...startProps}
												/>
											</Grid>
											<Grid item xs={6}>
												{" "}
												<TextField
													disabled={status !== "init"}
													fullWidth
													variant="outlined"
													{...endProps}
												/>
											</Grid>
										</Grid>
									)}
									value={inputs.inputDates}
								/>
							</Grid>
							<Grid item xs={12}>
								<FormField
									label="Location"
									type="text"
									name="location"
									disabled={status !== "init"}
									value={inputs.location}
									onChange={(val) => setInputs({ ...inputs, location: val.target.value })}
									required
									// defaultValue={service?.name}
								/>
							</Grid>
							<Grid item xs={12}>
								<FormField
									label="Details"
									type="multiline"
									name="details"
									value={inputs.details}
									onChange={(val) => setInputs({ ...inputs, details: val.target.value })}
									disabled={status !== "init"}
									// defaultValue={service?.name}
								/>
							</Grid>
						</Grid>

						<Box display="flex" flexDirection="row-reverse" sx={{ pt: 2 }}>
							{status === "init" && (
								<>
									<StandardButton
										onClick={modal === "create" ? handleCreate : handleUpdate}
										buttonStyle="primary"
										text={modal === "edit" ? "Update Course Date" : "Save Course Date"}
									/>
									<StandardButton
										onClick={modal === "create" ? toggleCreate : toggleEdit}
										sx={{ mr: 2 }}
										buttonStyle="secondary"
										text="Close"
									/>
								</>
							)}
							{status === "loading" && (
								<Box sx={{ pt: 2, pl: 14 }}>
									<CircularProgress />
								</Box>
							)}
							{status === "fin" && (
								<>
									{modal === "create" && (
										<StandardButton
											onClick={resetForm}
											buttonStyle="tertiary purple"
											text="Add Another"
										/>
									)}
									<StandardButton
										onClick={modal === "create" ? toggleCreate : toggleEdit}
										sx={{ mr: 2 }}
										buttonStyle="secondary"
										text="Close"
									/>
								</>
							)}
						</Box>
					</CardContent>
				</Card>
			</Dialog>
		</Card>
	);
};

export default CourseDateTable;
