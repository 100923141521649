import { Box, Card, Container, Typography } from "@material-ui/core";
import FormField from "components/FormField/FormField";
import StandardButton from "components/StandardButton/StandardButton";
import React, { useEffect, useState } from "react";
import providerTypes from "utils/offeringTypes";
import SearchResult from "../Components/listItems/SearchResult";
import ViewStoreProvider from "../Provider/ViewStoreProvider";

const SearchResultsPage = ({ offerings, categoriesFilterOptions, defaultCategory, defaultType, defaultProvider }) => {
	const [searchResults, setSearchResults] = useState([]);
	const [category, setCategory] = useState(null);
	const [provider, setProvider] = useState(null);
	const [type, setType] = useState(null);

	useEffect(() => {
		let offeringsData = offerings;

		// Now filter the data based on category AND/OR type
		if (category) {
			offeringsData = offeringsData.filter((o) => o.categories.includes(category));
		}

		if (type) {
			offeringsData = offeringsData.filter((o) => o.type === type);
		}

		if (provider) {
			offeringsData = offeringsData.filter((o) => o.provider.providerId === provider);
		}

		setSearchResults(offeringsData);
	}, [offerings, category, type, provider]);

	useEffect(() => {
		if (defaultType) setType(defaultType);
		if (defaultCategory) setCategory(defaultCategory);
		if (defaultProvider) setProvider(defaultProvider);
	}, [defaultType, defaultCategory, defaultProvider]);

	// Get existing results and filter them

	return (
		<>
			{provider && <ViewStoreProvider />}

			<Container>
				<Card sx={{ mt: 3, mb: 2, p: 2 }}>
					<Box display="flex" justifyContent="space-between" sx={{ width: "100%" }}>
						<Box sx={{ width: 300, mr: 2 }}>
							{providerTypes ? (
								<FormField
									type="select"
									label="Offering Type"
									options={providerTypes}
									onChange={(val) => setType(val?.value || undefined)}
									value={
										type
											? {
													label: providerTypes?.find((c) => c.value === type)?.label || "",
													value: type,
											  }
											: null
									}
								/>
							) : (
								<div />
							)}
						</Box>
						<Box sx={{ width: 300 }}>
							{categoriesFilterOptions?.length && (
								<FormField
									type="select"
									label="Category"
									options={categoriesFilterOptions || []}
									onChange={(val) => setCategory(val?.value || undefined)}
									value={
										category
											? {
													label:
														categoriesFilterOptions?.find((c) => c.value === category)
															?.label || "",
													value: category,
											  }
											: null
									}
								/>
							)}
						</Box>
						<Box>
							<StandardButton
								buttonStyle="secondary"
								text="Reset Filters"
								onClick={() => {
									setCategory(undefined);
									setType(undefined);
									setProvider(undefined);
								}}
							/>
						</Box>
					</Box>
				</Card>

				<Box display="flex" flexWrap="wrap">
					{searchResults.length ? (
						searchResults?.map((r, i) => (
							<>
								<Box sx={{ p: 0.5 }}>
									<SearchResult data={r} key={i} />
								</Box>
							</>
						))
					) : (
						<Container
							maxWidth="lg"
							sx={{
								alignItems: "center",
								display: "flex",
								py: 6,
							}}>
							<div>
								<Typography color="textSecondary" sx={{ mt: 1 }} variant="body1">
									Your search did not return any results. Try clearing your filters.
								</Typography>
							</div>
						</Container>
					)}
				</Box>
			</Container>
		</>
	);
};

export default SearchResultsPage;
