const locationsList = [
	{ label: "Sydney, Australia", value: "SYDNEY_AU" },
	{ label: "Melbourne, Australia", value: "MELBOURNE_AU" },
	{ label: "Brisbane, Australia", value: "BRISBANE_AU" },
	{ label: "Gold Coast, Australia", value: "GOLD_COAST_AU" },
	{ label: "Adelaide, Australia", value: "ADELAIDE_AU" },
	{ label: "Perth, Australia", value: "PERTH_AU" },
	{ label: "Darwin, Australia", value: "DARWIN_AU" },
	{ label: "Alice Springs, Australia", value: "ALICE_SPRINGS_AU" },
	{ label: "Canberra, Australia", value: "CANBERRA_AU" },
	{ label: "Townsville, Australia", value: "TOWNSVILLE_AU" },
	{ label: "Cairns, Australia", value: "CAIRNS_AU" },
	{ label: "Newcastle, Australia", value: "NEWCASTLE_AU" },
	{ label: "Wellington, New Zealand", value: "WELLINGTON_NZ" },
	{ label: "Auckland, New Zealand", value: "AUCKLAND_NZ" },
	{ label: "Singapore", value: "SINGAPORE_SG" },
	{ label: "Los Angeles, Unites States", value: "LOS_ANGELES_US" },
	{ label: "San Francisco, Unites States", value: "SAN_FRANCISCO_US" },
	{ label: "San Diego, Unites States", value: "SAN_DIEGO_US" },
	{ label: "New York, Unites States", value: "NEW_YORK_US" },
	{ label: "Miami, Unites States", value: "MIAMI_US" },
	{ label: "Austin, Unites States", value: "AUSTIN_US" },
	{ label: "Salt Lake City, Unites States", value: "SALT_LAKE_CITY_US" },
	{ label: "Seattle, Unites States", value: "SEATTLE_US" },
	{ label: "London, United Kingdom", value: "LONDON_UK" },
	{ label: "Leeds, United Kingdom", value: "LEEDS_UK" },
	{ label: "Sheffield, United Kingdom", value: "SHEFFIELD_UK" },
	{ label: "Toronto, Canada", value: "TORONTO_CA" },
	{ label: "Montreal, Canada", value: "MONTREAL_CA" },
	{ label: "Vancouver, Canada", value: "VANCOUVER_CA" },
];

export default locationsList;
