import { gql } from "@apollo/client";

const UpdateOffering = gql`
	mutation UpdateOffering($payload: updateOfferingInput!) {
		updateOffering(payload: $payload) {
			... on Offering {
				offeringId
				providerId
				name
				availability
				description
				costPrice
				salePrice
				delivery
				duration
				workload
				categories
				updatedAt
				createdAt
				summary
				coverPic
				data
			}
			... on Error {
				message
			}
		}
	}
`;

export default UpdateOffering;
