import { Avatar, Box, Fab } from "@material-ui/core";
import AddPhotoAlternate from "@material-ui/icons/AddPhotoAlternate";
import ImageUploadModal from "components/common/ImageUpload/ImageUploadModal";

const DisplayPicEditable = ({
	image,
	open,
	toggle,
	imageType = "displayPic",
	refetch = false,
	disabled = false,
	ownerId,
	ownerType,
}) => (
	<Box
		sx={{
			left: 24,
			position: "absolute",
			top: -60,
			width: 120,
			"@media (max-width:800px)": {
				height: 100,
				width: 100,
				top: -35,
				left: 0,
			},
			height: 120,
			borderRadius: 60,
			overflow: "hidden",
			border: (theme) => `4px solid ${theme.palette.background.default}`,
		}}>
		<Avatar
			src={image}
			sx={{
				height: "100%",
				width: "100%",
			}}
		/>
		{!disabled && (
			<Box
				sx={{
					position: "absolute",
					top: 0,
					display: "flex",
					justifyContent: "center",
					height: "100%",
					width: "100%",
					cursor: "pointer",
					backgroundColor: "rgba(0,0,0,0.5)",
					opacity: 0,
					"&:hover": {
						opacity: 1,
					},
				}}>
				<Fab
					onClick={toggle}
					size="small"
					sx={{
						alignSelf: "center",
						cursor: "pointer",
						opacity: 1,
					}}>
					<AddPhotoAlternate fontSize="small" />
				</Fab>
			</Box>
		)}
		<ImageUploadModal
			image={image}
			open={open}
			onClose={toggle}
			imageType={imageType}
			refetch={refetch}
			ownerId={ownerId}
			ownerType={ownerType}
		/>
	</Box>
);

export default DisplayPicEditable;
